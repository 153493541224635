import React, { FunctionComponent, useState } from "react";
import { css } from "@emotion/core";
import { Button, Card, Divider, Form, Input, message } from "antd";

import { ticketStatusToText } from "../../../../utils/ticket";
import { formItemLayout, formItemLayoutWithOutLabel } from "../../../../styles/form";

import { AddTaskDetailReq, addTasks, setTicketStatus } from "../../../../api/ticket";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import Timeline from "../../../../components/timeline";
import RejectTicketForm, { RejectTicketFormProps } from "./ticket-reject";
import ApproveTicketForm from "./ticket-approve";
import { Organization, TicketStatus } from "../../../../models";
import { TicketComponentProps } from "../TicketComponentProps";

const UnderReviewTicketDetail: FunctionComponent<TicketComponentProps> = ({ ticket, refreshTicketFn }) => {
  const [showRejectForm, setShowRejectForm] = useState<boolean>(false);
  const [showApproveForm, setShowApproveForm] = useState<boolean>(false);

  let rejectFormRef: any;
  let approveFormRef: any;

  const saveApproveFormRef = (formRef: any) => {
    approveFormRef = formRef;
  };

  const saveRejectFormRef = (formRef: any) => {
    rejectFormRef = formRef;
  };

  const handleApproveTicket = async (organizations: Organization[], auditComment?: string) => {
    try {
      let tasks: AddTaskDetailReq[] = [];
      for (const organization of organizations || []) {
        tasks.push({
          name: organization.name,
          assignedOrg: organization.id,
          taskType: organization.service,
        });
      }
      await addTasks(ticket.id, tasks, auditComment);
      setShowApproveForm(false);
      await refreshTicketFn();
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleRejectTicket = async () => {
    // TODO 下次需要将直接操作内层Form的行为屏蔽
    const { form } = rejectFormRef.props;
    form.validateFields(async (err: any, values: RejectTicketFormProps) => {
      if (err) {
        return;
      }

      try {
        const reason = rejectFormRef.getReason();
        await setTicketStatus(ticket.id, TicketStatus.Rejected, reason);
        rejectFormRef.reset();
        setShowRejectForm(false);
        await refreshTicketFn();
      } catch (err) {
        message.error(err.message);
      }
    });
  };

  const handleCancelApprove = () => {
    approveFormRef.reset();
    setShowApproveForm(false);
  };

  const handleCancelReject = () => {
    rejectFormRef.reset();
    setShowRejectForm(false);
  };

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      <Divider />
      <div css={NoMargin}>
        <Form.Item label="工单状态" {...formItemLayout}>
          {ticketStatusToText(ticket.status)}
        </Form.Item>
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button
            type="primary"
            onClick={() => {
              setShowApproveForm(true);
            }}
            css={ButtonMargin}>
            同意
          </Button>
          <Button
            type="danger"
            onClick={() => {
              setShowRejectForm(true);
            }}>
            拒绝
          </Button>
        </Form.Item>
      </div>

      {showRejectForm ? (
        <RejectTicketForm
          wrappedComponentRef={saveRejectFormRef}
          visible={showRejectForm}
          onCancel={handleCancelReject}
          onSubmit={handleRejectTicket}
        />
      ) : null}
      {showApproveForm ? (
        <ApproveTicketForm
          wrappedComponentRef={saveApproveFormRef}
          visible={showApproveForm}
          onCancel={handleCancelApprove}
          onSubmit={handleApproveTicket}
        />
      ) : null}

      <Divider />
      <Timeline ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default UnderReviewTicketDetail;
const NoMargin = css`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const ButtonMargin = css`
  margin-right: 14px;
`;
