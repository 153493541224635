import React from "react";
import QueueAnim from "rc-queue-anim";
import { Button, Row } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { getChildrenToRender } from "./utils";
import { css } from "@emotion/core";

class Content2 extends React.PureComponent {
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { wrapper, titleWrapper, page, OverPack: overPackData, childWrapper } = dataSource;
    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>{titleWrapper.children.map(getChildrenToRender)}</div>
          <OverPack {...overPackData}>
            <QueueAnim type="bottom" key="block" leaveReverse component={Row} componentProps={childWrapper}>
              <div css={downloadStyles} style={{ backgroundImage: `url(${childWrapper.background.src})` }}>
                <div className={"download-pane"}>
                  <div key={"title"}>{childWrapper.children.map(getChildrenToRender)}</div>
                  <div key={"buttons"} className={"download-btns"}>
                    {childWrapper.buttons.map(button => (
                      <Button key={button.children} icon={button.icon} shape={"round"} onClick={button.onClick}>
                        {button.children}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

const downloadStyles = css`
  //display: block;
  height: 520px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .download-pane {
    display: inline-block;
    width: 400px;
    //height: 200px;
    text-align: center;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    .download-btns {
      padding: 20px 10px;
      button {
        margin-left: 10px;
        &:first-of-type {
          margin-left: 0;
        }
        & > i {
          color: #e6b422;
        }
      }
    }
  }
`;

export default Content2;
