import { ServiceType, TaskWithTicket } from "../../models";
import { getTasksInOrg } from "../../api/task";
import SearchForm from "../vendor/search-form";
import React from "react";
import { ListViewConfig } from "./list-view-config";
import { formatTask } from "./formatters";
import { DefaultTaskColumnConfig } from "./DefaultColumnConfig";

export const RepairQueryListViewConfig: ListViewConfig<TaskWithTicket>[] = [
  {
    key: "repair-task-query",
    columns: DefaultTaskColumnConfig,
    title: "搜索",
    rowKey: "id",
    fetch: async (pageNo, pageSize, params) =>
      formatTask(
        await getTasksInOrg({
          taskType: ServiceType.REPAIR,
          page: pageNo,
          ...params,
        }),
      ),
    navBar: props => <SearchForm filterChange={props.filterChange} />,
  },
];
