import { css } from "@emotion/core";

import Background from "../../assets/home_main_bg.png";

export const Layout = css`
  height: 100vh;
  overflow: auto;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Header = css`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
`;

export const Logo = css`
  height: 64px;

  img {
    height: 23px;
  }
`;

export const Menu = css`
  line-height: 64px;
`;

export const Content = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = css`
  padding: 30px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const BrowserWarning = css`
  text-align: center;
`;

export const ErrorMessage = css`
  margin-bottom: 30px;
`;

export const LoginForm = css`
  width: 380px;

  .ant-form-item:last-of-type {
    margin-bottom: 0;
  }
`;

export const LoginButton = css`
  width: 100%;
  margin-bottom: 0;
`;

export const Footer = css`
  margin: 0 auto;
  color: #ffffff;
  background: transparent;
`;
export const LoginFormTitleStyle = css`
  color: #001529 !important;
  text-align: center;
`;
