import MapsError from "../../utils/MapsError";
import { OrgType, ServiceType, UserLevel } from "../../models";

export enum UserType {
  ADMIN = 1,
  OPERATOR = 2,
  VENDOR_ADMIN = 1,
  VENDOR_OPERATOR = 2,
}

export interface OrgInfo {
  id: number;
  customerType: number;
  name: string;
  description: string;
  service: ServiceType;
}

export interface UserInfo {
  id: number;
  userName: string;
  description: string;
  firstName: string;
  lastName: string;
  level: UserLevel;
  orgType: OrgType;
  organization: OrgInfo;
}

export enum UserInfoActionTypes {
  FETCH_REQUEST = "USER_INFO/FETCH_REQUEST",
  FETCH_SUCCESS = "USER_INFO/FETCH_SUCCESS",
  FETCH_ERROR = "USER_INFO/FETCH_ERROR",
}

export interface UserInfoState {
  readonly loading: boolean;
  readonly error?: MapsError;
  readonly data?: UserInfo;
}
