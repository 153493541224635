export interface Issue {
  id: number;
  issue: string;
}

export interface IssueGroup {
  id: number;
  name: string;
  items: Issue[];
}

export enum IssueActionTypes {
  SET = "ISSUE/SET",
  ADD_GROUP = "ISSUE/ADD_GROUP",
  REMOVE_GROUP = "ISSUE/REMOVE_GROUP",
  ADD_ISSUE = "ISSUE/ADD_ISSUE",
  REMOVE_ISSUE = "ISSUE/REMOVE_ISSUE",
}

export interface IssueState extends Array<IssueGroup> {}
