import { push } from "connected-react-router";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { Auth, AuthActionTypes } from "./types";
import { signInError, signInRequest, signInSuccess, signOutSuccess } from "./actions";
import { signIn } from "../../api/user";

import { ApplicationState } from "../index";
import { UserType } from "../userinfo/types";
import { CustomerLoginUrl, OpmLoginUrl, VendorLoginUrl } from "../../config";
import { OrgType, ServiceType, UserLevel } from "../../models";

// FIXME: this is a selector, let's gather selectors in one place
const getAuth = (state: ApplicationState) => state.auth.data;

function* handleSignIn(action: ReturnType<typeof signInRequest>) {
  try {
    const { username, password, orgType, orgName } = action.payload;

    let payload: any = {
      username,
      password,
      orgType,
      channel: orgName,
      identifierInfo: {
        appChannel: 3,
        appVersion: 0,
      },
    };
    if (orgType !== OrgType.CUSTOMER && orgType !== OrgType.VENDOR && orgType !== OrgType.PLATFORM) {
      yield put(signInError("客户/供应商/平台编号错误！"));
    }
    const res = yield call(signIn, payload);
    const token: string = res.token;
    // 暂时将token存入session storage，以便api函数可以获得
    window.sessionStorage.setItem("tk", token);
    const userId: number = res.user.id;
    const userLevel: UserType = res.user.level;
    const org: number = res.user.organization.id;

    const auth: Auth = {
      orgType,
      orgId: org,
      orgName,
      userId,
      userName: res.user.userName,
      userLevel,
      token,
    };
    let dashboardUrl: string = "";
    if (orgType === OrgType.CUSTOMER) {
      if (res.user.level === UserLevel.ADMIN) {
        dashboardUrl = "/dashboard/list-view/pending-list";
      } else if (res.user.level === UserLevel.STAFF) {
        dashboardUrl = "/dashboard/list-view/basic-list";
      }
    } else if (orgType === OrgType.VENDOR) {
      if (res.user.organization.service === ServiceType.EXPRESS) {
        dashboardUrl = "/dashboard/list-view/express-list";
      } else if (res.user.organization.service === ServiceType.REPAIR) {
        if (res.user.level === UserLevel.ADMIN) {
          dashboardUrl = "/dashboard/list-view/org-repair-list";
        } else {
          dashboardUrl = "/dashboard/list-view/my-repair-list";
        }
      }
    } else if (orgType === OrgType.PLATFORM) {
      dashboardUrl = "/dashboard/opm/org/list";
    }
    yield put(signInSuccess(auth));
    yield put(push(dashboardUrl));
  } catch (err) {
    yield put(signInError(err.message));
  }
}

function* handleSignOut() {
  const auth: Auth = yield select(getAuth);
  let loginUrl;
  if (auth.orgType === OrgType.CUSTOMER) {
    loginUrl = CustomerLoginUrl;
  } else if (auth.orgType === OrgType.VENDOR) {
    loginUrl = VendorLoginUrl;
  } else {
    loginUrl = OpmLoginUrl;
  }
  window.sessionStorage.removeItem("tk");
  yield put(signOutSuccess());
  yield put(push(loginUrl));
}

function* watchSignInRequest() {
  yield takeEvery(AuthActionTypes.SIGN_IN_REQUEST, handleSignIn);
}

function* watchSignOutRequest() {
  yield takeEvery(AuthActionTypes.SIGN_OUT_REQUEST, handleSignOut);
}

function* authSaga() {
  yield all([fork(watchSignInRequest), fork(watchSignOutRequest)]);
}

export default authSaga;
