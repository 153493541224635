import React, { FunctionComponent } from "react";
import { css } from "@emotion/core";
import { Button, Col, DatePicker, Form, Icon, Input, Row } from "antd";
import { FormComponentProps } from "antd/es/form";
import { ListViewNavBarComponentProps } from "../list-view/list-view-config";

const SearchForm: FunctionComponent<FormComponentProps & ListViewNavBarComponentProps> = ({ form, filterChange }) => {
  const { getFieldDecorator } = form;
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      await filterChange({
        keyword: values.keyword || undefined,
        from: values.range && values.range.length > 0 ? values.range[0].format("YYYY-MM-DD") : undefined,
        until: values.range && values.range.length > 0 ? values.range[1].format("YYYY-MM-DD") : undefined,
      });
    });
  };
  return (
    <Form css={SearchFormStyle} onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item label="关键字">
            {getFieldDecorator("keyword", {
              rules: [{ required: false }],
            })(<Input prefix={<Icon type="file-search" css={InputIcon} />} placeholder="请输入关键字" />)}
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item label="时间">
            {getFieldDecorator("range", {
              rules: [{ required: false }],
            })(<DatePicker.RangePicker format="YYYY-MM-DD" css={FullWidth} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            <Icon type="search" /> 搜索
          </Button>
          {/*<Button style={{ marginLeft: 8 }} onClick={}>*/}
          {/*  <Icon type="redo" /> 重置*/}
          {/*</Button>*/}
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create<FormComponentProps & ListViewNavBarComponentProps>({ name: "SearchFormNavBar" })(SearchForm);

const InputIcon = css`
  color: rgba(0, 0, 0, 0.25);
`;

const FullWidth = css`
  width: 100%;
`;

const SearchFormStyle = css`
  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;
