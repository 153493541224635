import React, { FunctionComponent, useState } from "react";
import { css } from "@emotion/core";
import { Button, Card, Divider, Form, message } from "antd";

import { formItemLayout, formItemLayoutWithOutLabel } from "../../../../styles/form";
import { setTaskStatus } from "../../../../api/task";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import RejectAssignmentForm, { RejectAssignmentFormProps } from "./reject-assignment-form";
import { TaskStatus } from "../../../../models";
import { TaskComponentProps } from "../TaskComponentProps";
import TimelineComponent from "../../../../components/timeline";

const Assignment: FunctionComponent<TaskComponentProps> = ({ ticket, task, refreshTaskFn, refreshTicketFn }) => {
  const [showRejectForm, setShowRejectForm] = useState<boolean>(false);
  let rejectFormRef: any;
  const saveRejectFormRef = (formRef: any) => {
    rejectFormRef = formRef;
  };

  const handleAcceptAssignment = async () => {
    try {
      const res = await setTaskStatus(task.id, TaskStatus.Accepted);
      await refreshTaskFn(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleRejectAssignment = () => {
    const { form } = rejectFormRef.props;
    form.validateFields(async (err: any, values: RejectAssignmentFormProps) => {
      if (err) {
        return;
      }
      try {
        const res = await setTaskStatus(task.id, TaskStatus.Rejected);
        rejectFormRef.reset();
        await refreshTaskFn(res);
      } catch (err) {
        message.error(err.message);
      }
      setShowRejectForm(false);
    });
  };

  const handleCancelReject = () => {
    rejectFormRef.reset();
    setShowRejectForm(false);
  };

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      <Divider />
      <Form.Item label="备注" {...formItemLayout}>
        {task.comment}
      </Form.Item>
      <Form.Item label="维修人员信息" {...formItemLayout}>
        {task.assignDesc}
      </Form.Item>
      <Form.Item label="实际到店日期" {...formItemLayout}>
        {task.serviceTime}
      </Form.Item>
      <Form.Item {...formItemLayoutWithOutLabel}>
        <Button type="primary" onClick={handleAcceptAssignment} css={ButtonMargin}>
          同意
        </Button>
        <Button type="danger" onClick={() => setShowRejectForm(true)}>
          拒绝
        </Button>
      </Form.Item>

      <RejectAssignmentForm
        wrappedComponentRef={saveRejectFormRef}
        visible={showRejectForm}
        onCancel={handleCancelReject}
        onSubmit={handleRejectAssignment}
      />
      <Divider />
      <TimelineComponent ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default Assignment;
const ButtonMargin = css`
  margin-right: 14px;
`;
