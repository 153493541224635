import React, { ReactNode } from "react";
import { Modal } from "antd";

export function preview(title: string, image: string, icon: string | ReactNode) {
  Modal.info({
    mask: true,
    maskClosable: true,
    centered: true,
    title,
    icon,
    content: <img src={image} width={"100%"} />,
  });
}
