import isEmpty from "lodash/isEmpty";
import { getEndpoint } from "../config";
import MapsError from "./MapsError";
import { message } from "antd";

export interface KVP {
  [key: string]: any;
}

// remove all fields whose value is undefined
const cleanObj = (obj?: KVP): KVP => {
  if (obj === undefined) {
    return {};
  }

  return Object.keys(obj).reduce((object: KVP, key) => {
    if (obj[key] !== undefined) {
      object[key] = obj[key];
    }
    return object;
  }, {});
};

async function callApi({
  method,
  endpoint,
  path,
  body,
  params,
}: {
  method: string;
  endpoint: string;
  path: string;
  body?: KVP;
  params?: KVP;
}) {
  let url = `${endpoint}${path}`;

  // add url params
  const cleanedParams = cleanObj(params);
  if (!isEmpty(cleanedParams)) {
    const paramString = new URLSearchParams(cleanedParams);
    url = `${url}?${paramString.toString()}`;
  }

  try {
    let headers: HeadersInit = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (sessionStorage.getItem("tk")) {
      headers["Authorization"] = sessionStorage.getItem("tk") as string;
    }
    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new MapsError(-1 * (1000 + response.status), response.statusText);
    }
    if (response.headers.get("Content-Type") === "application/octet-stream") {
      return await response.blob();
    }
    const result = await response.json();
    if (result.status) {
      return result.data;
    } else {
      if (result.data.code === 414 || result.data.code === 417) {
        message.warning("授权过期，3秒钟后将重新登录", 3, () => {
          window.location.replace(`/login/${sessionStorage.getItem("LoginType") || "customer"}`);
        });
        return;
      }
      throw new MapsError(result.data.code, result.data.message);
    }
  } catch (err) {
    if (!(err instanceof MapsError)) {
      throw new MapsError(-1, err.message);
    } else {
      throw err;
    }
  }
}

export async function GET({ path, params }: { path: string; params?: KVP }) {
  return await callApi({
    method: "GET",
    endpoint: getEndpoint(),
    path,
    params,
  });
}

export async function POST({ path, body, params }: { path: string; body?: KVP; params?: KVP }) {
  return await callApi({
    method: "POST",
    endpoint: getEndpoint(),
    path,
    body,
    params,
  });
}

export async function PUT({ path, body, params }: { path: string; body?: KVP; params?: KVP }) {
  return await callApi({
    method: "PUT",
    endpoint: getEndpoint(),
    path,
    body,
    params,
  });
}

export async function DELETE({ path, body, params }: { path: string; body?: KVP; params?: KVP }) {
  return await callApi({
    method: "DELETE",
    endpoint: getEndpoint(),
    path,
    body,
    params,
  });
}
