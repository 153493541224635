import React from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { FormComponentProps } from "antd/es/form";
import { OptionProps } from "antd/lib/select";

import { Operator } from "./need-assign";
import { formItemLayout, formItemLayoutWithOutLabel } from "../../../../styles/form";
import { Moment } from "moment";

export interface AssignTaskFormProps extends FormComponentProps {
  assignDesc?: string;
  serviceTime?: Moment;
  assignTo?: number;
}

interface OwnProps {
  people: Operator[];
  onSubmit: (e: React.FormEvent) => void;
}

type AllProps = AssignTaskFormProps & OwnProps;

class AssignTaskForm extends React.Component<AllProps> {
  static defaultProps = {
    people: [],
    onSubmit: (e: React.FormEvent) => {},
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.setState({ otherReason: false });
  };

  render() {
    const { onSubmit, people, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <Form.Item label="维修人员信息">
          {getFieldDecorator("assignDesc")(<Input placeholder="维修人员信息" />)}
        </Form.Item>
        <Form.Item label="实际到店日期">
          {getFieldDecorator("serviceTime")(<DatePicker placeholder="实际到店日期" />)}
        </Form.Item>
        {people.length === 0 ? (
          <Form.Item {...formItemLayoutWithOutLabel}>无维修人员，请先添加维修人员，才能继续分配任务</Form.Item>
        ) : (
          <Form.Item label="分配任务">
            {getFieldDecorator("assignTo", {
              rules: [{ required: true, message: "请选择维修员" }],
            })(
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="选择维修员"
                optionFilterProp="children"
                filterOption={(input: string, option: React.ReactElement<OptionProps>) =>
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {people.map((person: Operator) => (
                  <Select.Option key={person.id} value={person.id}>
                    {person.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        )}
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="primary" htmlType="submit" style={{ width: 140 }}>
            提交
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedAssignTaskForm = Form.create<AllProps>({
  name: "assign_task",
})(AssignTaskForm);

export default WrappedAssignTaskForm;
