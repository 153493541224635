import { TicketStatus, TicketStatusDescriptions } from "../models";

export const ticketStatusToText = (status: TicketStatus): string => {
  return TicketStatusDescriptions[status];
};

export const getValidTicketStatus = () => [
  TicketStatus.UnderReview,
  TicketStatus.Approved,
  TicketStatus.ToBeRated,
  TicketStatus.Finished,
  TicketStatus.Rejected,
  TicketStatus.Closed,
];
