import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Alert, Button, Form, Icon, Input, Layout, Menu, Typography } from "antd";
import * as Style from "./style";
import { Link } from "react-router-dom";
import { CustomerLoginUrl, VendorLoginUrl } from "../../config";
import { isIE } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { AuthState, SignInRequestType } from "../../store/auth/types";
import { signInRequest } from "../../store/auth/actions";
import { FormComponentProps } from "antd/es/form";
import { useParams } from "react-router";
import { OrgType } from "../../models";
import logo from "../../assets/logo.svg";

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

interface LoginFormProps {
  email: string;
  password: string;
  orgName: string;
}

const getIEWarning = () => {
  const message = (
    <span>
      尊敬的用户，使用老版本IE浏览器可能存在一定的不兼容性，为了更好的用户体验，建议使用Google Chrome浏览器，
      <a href="http://www.chromeliulanqi.com/Chrome_Latest_Setup.exe">点击下载</a>
    </span>
  );
  return <Alert banner showIcon={false} message={message} css={Style.BrowserWarning} />;
};

const LoginTypes: {
  [loginType: string]: OrgType;
} = { customer: OrgType.CUSTOMER, vendor: OrgType.VENDOR, opm: OrgType.PLATFORM };

const Configs: { [orgType: number]: string[] } = {
  [OrgType.CUSTOMER]: ["客户登录", "客户编号"],
  [OrgType.VENDOR]: ["供应商登录", "供应商编号"],
  [OrgType.PLATFORM]: ["平台登录", "平台编号"],
};

const DEFAULT_CONFIG = ["", ""];

const Login: FunctionComponent<FormComponentProps> = ({ form }) => {
  const { error: authError, loading: authLoading } = useSelector<ApplicationState, AuthState>(state => state.auth);
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const signIn = useCallback((request: SignInRequestType) => dispatch(signInRequest(request)), [dispatch]);
  const { loginType = "customer" } = useParams();
  const orgType: OrgType = LoginTypes[loginType.toLowerCase()];
  const config: string[] = Configs[orgType] || DEFAULT_CONFIG;
  useEffect(() => {
    sessionStorage.setItem("LoginType", loginType);
  }, [loginType]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields((err, values: LoginFormProps) => {
      if (err) {
        return;
      }
      const signInPayload: SignInRequestType = {
        username: values.email,
        password: values.password,
        orgType: orgType,
        orgName: values.orgName.toLowerCase(),
      };
      signIn(signInPayload);
    });
  };
  return (
    <Layout css={Style.Layout}>
      <Header style={{ background: "white", height: "66px" }}>
        <div css={Style.Header}>
          <div css={Style.Logo}>
            <Link to="/">
              <img src={logo} alt="MAPS" />
            </Link>
          </div>

          <Menu css={Style.Menu} theme="light" mode="horizontal" selectable={false}>
            <Menu.Item key="customer">
              <Link to={CustomerLoginUrl}>客户登录</Link>
            </Menu.Item>
            <Menu.Item key="vendor">
              <Link to={VendorLoginUrl}>供应商登录</Link>
            </Menu.Item>
            {/*<Menu.Item key="contact">*/}
            {/*  <a href="mailto:support@vigoroup.com">联系我们</a>*/}
            {/*</Menu.Item>*/}
          </Menu>
        </div>
      </Header>

      {isIE ? getIEWarning() : null}

      <Content css={Style.Content}>
        <div css={Style.FormContainer}>
          {authError ? <Alert css={Style.ErrorMessage} type="error" showIcon message={authError} /> : null}
          <Title level={4} css={Style.LoginFormTitleStyle}>
            {config[0]}
          </Title>
          <Form css={Style.LoginForm} onSubmit={handleSubmit}>
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  { type: "email", message: "请输入合法的电子邮件地址！" },
                  { required: true, message: "请输入电子邮件！" },
                ],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  size="large"
                  placeholder="电子邮件"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "请输入密码！" }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                  size="large"
                  type="password"
                  placeholder="密码"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("orgName", {
                rules: [{ required: true, message: `请输入${config[1]}！` }],
              })(
                <Input
                  prefix={<Icon type="number" style={{ color: "rgba(0,0,0,.25)" }} />}
                  size="large"
                  placeholder={config[1]}
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button css={Style.LoginButton} type="primary" size="large" loading={authLoading} htmlType="submit">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>

      <Footer css={Style.Footer}>©2015-2020 Vigorous All Rights Reserved （备案号：沪ICP备16016121号-1）</Footer>
    </Layout>
  );
};
export default Form.create({ name: "LoginForm" })(Login);
