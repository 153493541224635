import React, { FunctionComponent } from "react";
import { css } from "@emotion/core";
import { Button, Card, Divider, Form, message } from "antd";
import { useHistory } from "react-router";

import { ticketStatusToText } from "../../../../utils/ticket";
import { formItemLayout, formItemLayoutWithOutLabel } from "../../../../styles/form";

import { setTicketStatus } from "../../../../api/ticket";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import Timeline from "../../../../components/timeline";
import { TicketStatus } from "../../../../models";
import { TicketComponentProps } from "../TicketComponentProps";

const RejectedTicketDetail: FunctionComponent<TicketComponentProps> = ({ ticket, refreshTicketFn }) => {
  let history = useHistory();
  const editTicket = () => {
    // TODO 地址写死了
    const editTicketUrl = `/dashboard/customer/ticket/new`;
    history.push(editTicketUrl, { ticket });
  };

  const closeTicket = async () => {
    try {
      const res = await setTicketStatus(ticket.id, TicketStatus.Closed);
      await refreshTicketFn(res);
      // const newTicket = transformResponseToTicket(res);
      // addTicket(newTicket);
    } catch (err) {
      message.error(err.message);
    }
  };
  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      <Divider />
      <div css={NoMargin}>
        <Form.Item label="工单状态" {...formItemLayout}>
          {ticketStatusToText(ticket.status)}
        </Form.Item>
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="primary" onClick={editTicket} css={ButtonMargin}>
            编辑
          </Button>
          <Button type="danger" onClick={closeTicket}>
            放弃
          </Button>
        </Form.Item>
      </div>
      <Divider />
      <Timeline ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default RejectedTicketDetail;
const NoMargin = css`
  .ant-form-item {
    margin-bottom: 0;
  }
`;
const ButtonMargin = css`
  margin-right: 14px;
`;
