import { UserType } from "../userinfo/types";
import { OrgType } from "../../models";

export interface Auth {
  orgType: OrgType;
  orgId: number;
  orgName: string;
  userId: number;
  userName: string;
  userLevel: UserType;
  token: string;
}

export interface SignInRequestType {
  username: string;
  password: string;
  orgType: OrgType;
  orgName: string;
}

export enum AuthActionTypes {
  SIGN_IN_REQUEST = "AUTH/SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS = "AUTH/SIGN_IN_SUCCESS",
  SIGN_IN_ERROR = "AUTH/SIGN_IN_ERROR",
  SIGN_OUT_REQUEST = "AUTH/SIGN_OUT_REQUEST",
  SIGN_OUT_SUCCESS = "AUTH/SIGN_OUT_SUCCESS",
}

export interface AuthState {
  readonly loading: boolean;
  readonly error?: string;
  readonly data?: Auth;
}
