import React from "react";
import { css } from "@emotion/core";
import { Form, Modal, Table } from "antd";

import { getAttachmentUrl } from "../../utils/attachment";
import { formItemLayout } from "../../styles/form";
import * as Preview from "../../styles/preview";
import { Attachment, Task, TaskDetail, TaskDetailTypes } from "../../models";

interface OwnProps {
  task: Task;
  showPrice: boolean;
}

interface State {
  readonly previewVisible: boolean;
  readonly previewImage?: string;
  readonly previewTitle?: string;
}

const getMaterialColumns = (showPrice: boolean) => {
  if (showPrice) {
    return [
      {
        title: "品名",
        dataIndex: "name",
      },
      {
        title: "数量",
        dataIndex: "num",
      },
      {
        title: "单价",
        dataIndex: "price",
        render: (text: any, taskDetail: TaskDetail) => (taskDetail.price / 100).toFixed(2),
      },
    ];
  } else {
    return [
      {
        title: "品名",
        dataIndex: "name",
      },
      {
        title: "数量",
        dataIndex: "num",
      },
    ];
  }
};

const getExpressColumns = () => [
  {
    title: "快递公司",
    dataIndex: "company",
  },
  {
    title: "单号",
    dataIndex: "tracking",
  },
];

const getTotalPrice = (materials: any[]): number => {
  return materials.map(item => item.num * (item.price / 100)).reduce((a, b) => a + b, 0);
};

class ReadonlyExpressTask extends React.Component<OwnProps, State> {
  state = {
    previewVisible: false,
    previewImage: undefined,
    previewTitle: undefined,
  };

  static defaultProps = {
    showPrice: false,
  };

  handleCancel = () =>
    this.setState({
      previewVisible: false,
      previewImage: undefined,
      previewTitle: undefined,
    });

  handlePreview = (attachment: Attachment) => {
    this.setState({
      previewVisible: true,
      previewImage: getAttachmentUrl(attachment.attachmentId),
      previewTitle: attachment.attachmentName,
    });
  };

  render() {
    const { task, showPrice } = this.props;
    const materials = task.taskDetailList.filter(taskDetail => taskDetail.type === TaskDetailTypes.EXPRESS_MATERIALS);
    const totalMaterialPrice = getTotalPrice(materials);

    return (
      <Form {...formItemLayout} css={NoMargin}>
        <Form.Item label="任务内容">物料替换 ( <b>{task.name}</b> )</Form.Item>
        <Form.Item label="材料">
          <Table
            css={TableMargin}
            columns={getMaterialColumns(showPrice)}
            dataSource={materials}
            footer={() => (showPrice ? `合计：${totalMaterialPrice.toFixed(2)}` : null)}
            size="small"
            pagination={false}
            rowKey={(material, index) => index.toString()}
          />
        </Form.Item>
        <Form.Item label="快递">
          <Table
            columns={getExpressColumns()}
            dataSource={task.expressInfoList}
            size="small"
            pagination={false}
            rowKey={(express, index) => index.toString()}
          />
        </Form.Item>
        <Form.Item label="备注">{task.comment}</Form.Item>
        <Form.Item label="其他附件">
          {task.attachments?.length === 0
            ? "无"
            : task.attachments?.map(attachment => (
                <div css={Preview.PicCardContainer} key={attachment.attachmentId}>
                  <img
                    alt={attachment.attachmentName}
                    css={Preview.PicCardImg}
                    onClick={() => this.handlePreview(attachment)}
                    src={getAttachmentUrl(attachment.attachmentId)}
                  />
                </div>
              ))}
        </Form.Item>

        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={this.handleCancel}>
          <img alt={this.state.previewTitle} style={{ width: "100%" }} src={this.state.previewImage} />
        </Modal>
      </Form>
    );
  }
}

export default ReadonlyExpressTask;

const NoMargin = css`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const TableMargin = css`
  margin-bottom: 16px;
`;
