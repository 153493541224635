import { action } from "typesafe-actions";
import { Auth, AuthActionTypes, SignInRequestType } from "./types";

export const signInRequest = (request: SignInRequestType) => action(AuthActionTypes.SIGN_IN_REQUEST, request);

export const signInSuccess = (data: Auth) => action(AuthActionTypes.SIGN_IN_SUCCESS, data);

export const signInError = (error: string) => action(AuthActionTypes.SIGN_IN_ERROR, error);

export const signOutRequest = () => action(AuthActionTypes.SIGN_OUT_REQUEST);

export const signOutSuccess = () => action(AuthActionTypes.SIGN_OUT_SUCCESS);
