import React from "react";
import { css } from "@emotion/core";
import { Form, Modal, Row } from "antd";

import { getAttachmentUrl } from "../../utils/attachment";
import { formItemLayout } from "../../styles/form";
import * as Preview from "../../styles/preview";
import { Attachment, Ticket } from "../../models";

interface OwnProps {
  ticket: Ticket;
}

interface State {
  readonly previewVisible: boolean;
  readonly previewImage?: string;
  readonly previewTitle?: string;
}

class ReadonlyTicket extends React.Component<OwnProps, State> {
  state = {
    previewVisible: false,
    previewImage: undefined,
    previewTitle: undefined,
  };

  handleCancel = () =>
    this.setState({
      previewVisible: false,
      previewImage: undefined,
      previewTitle: undefined,
    });

  handlePreview = (attachment: Attachment) => {
    this.setState({
      previewVisible: true,
      previewImage: getAttachmentUrl(attachment.attachmentId),
      previewTitle: attachment.attachmentName,
    });
  };

  render() {
    const { ticket } = this.props;
    console.log(ticket);
    return (
      <Form {...formItemLayout} css={NoMargin}>
        <Form.Item label="渠道">{ticket.creator.organization.name}</Form.Item>
        <Form.Item label="店铺号">{ticket.orgSerial}</Form.Item>
        <Form.Item label="店铺名">{ticket.orgName}</Form.Item>
        <Form.Item label="店铺地址">{ticket.region.replace(/,/g, "") + ticket.orgAddress.address}</Form.Item>
        <Form.Item label="联系人（店长）">{ticket.contactName}</Form.Item>
        <Form.Item label="联系电话">{ticket.tel}</Form.Item>
        <Form.Item label="期望到店维修日期">{ticket.expectedDate}</Form.Item>
        {(ticket.issues || []).map((issue, index) => (
          <Form.Item key={issue.id} label={`问题${index + 1}`}>
            <Row>{`${issue.group}: ${issue.issue}`}</Row>
            <Row>数量{issue.count}</Row>
            <Row>
              {(issue.photos || []).map(photo => (
                <div css={Preview.PicCardContainer} key={photo.attachmentId}>
                  <img
                    alt={photo.attachmentName}
                    css={Preview.PicCardImg}
                    onClick={() => this.handlePreview(photo)}
                    src={getAttachmentUrl(photo.attachmentId)}
                  />
                </div>
              ))}
            </Row>
          </Form.Item>
        ))}

        <Form.Item label="其他问题">
          <Row>{ticket.extraIssue}</Row>
          {ticket.extraPhotos.map(photo => (
            <div css={Preview.PicCardContainer} key={photo.attachmentId}>
              <img
                alt={photo.attachmentName}
                css={Preview.PicCardImg}
                onClick={() => this.handlePreview(photo)}
                src={getAttachmentUrl(photo.attachmentId)}
              />
            </div>
          ))}
        </Form.Item>
        {ticket.auditComment ? <Form.Item label="审批批注">{ticket.auditComment}</Form.Item> : null}

        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={this.handleCancel}>
          <img alt={this.state.previewTitle} style={{ width: "100%" }} src={this.state.previewImage} />
        </Modal>
      </Form>
    );
  }
}

export default ReadonlyTicket;

const NoMargin = css`
  .ant-form-item {
    margin-bottom: 0;
  }
`;
