import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router";

import HomePage from "./pages/home";
import Login from "./pages/login";
import NotFound from "./components/not-found";
import { Dashboard } from "./pages/Dashboard";

const Routes: FunctionComponent = () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/login/:loginType(customer|opm|vendor)" component={Login} />
    <Route path={"/dashboard"} component={Dashboard} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
