import {GET, KVP, POST, PUT} from "../utils/api";
import {ServiceType, TicketStatus} from "../models";

export async function createTicket(payload: any) {
  return POST({
    path: "/ticket",
    body: payload,
  });
}

export async function getTicket(ticketId: number) {
  return GET({
    path: `/ticket/${ticketId}`,
  });
}

export async function getMyTickets(filter?: KVP) {
  return GET({
    path: `/myTickets`,
    params: filter,
  });
}

export async function getTicketList(filter?: KVP) {
  return GET({
    path: `/ticketList`,
    params: filter,
  });
}

export async function addComment(ticketId: number, comment: string) {
  return POST({
    path: `/ticket/${ticketId}/timeline`,
    body: {
      comment,
    },
  });
}

export async function setTicketStatus(ticketId: number, status: TicketStatus, comment?: string) {
  return PUT({
    path: `/ticket/${ticketId}`,
    body: {
      status,
      comment,
    },
  });
}

export interface AddTaskDetailReq {
  name: string;
  assignedOrg: number;
  taskType: ServiceType;
}

export async function addTasks(ticketId: number, tasks: AddTaskDetailReq[], auditComment?: string) {
  return POST({path: `/ticket/${ticketId}/task`, body: {details: tasks, auditComment}});
}

export async function getBrandTicketReport(from: string, to: string) {
  return GET({path: `/MyBrandTicketReport`, params: {from, to}});
}

export async function getMyTicketReport(from: string, to: string) {
  return GET({path: `/MyTicketReport`, params: {from, to}});
}
