import { GET, POST } from "../utils/api";
import { Brand } from "../models";

export async function getBrandList() {
  return GET({
    path: "/brand",
  });
}

export async function saveBrand(brand: Brand) {
  return POST({
    path: "/org/brand",
    body: brand,
  });
}
