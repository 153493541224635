import { ServiceType, TaskWithTicket } from "../../models";
import { getTasksInOrg } from "../../api/task";
import { ListViewConfig } from "./list-view-config";
import { formatTask } from "./formatters";
import { DefaultTaskColumnConfig } from "./DefaultColumnConfig";

export const RepairAdminListViewConfig: ListViewConfig<TaskWithTicket>[] = [
  {
    key: "org-repair-list-1",
    columns: DefaultTaskColumnConfig,
    title: "待分配",
    rowKey: "id",
    fetch: async (pageNo, pageSize) =>
      formatTask(
        await getTasksInOrg({
          taskType: ServiceType.REPAIR,
          page: pageNo,
          assigned: 0,
        }),
      ),
  },
  {
    key: "org-repair-list-2",
    columns: DefaultTaskColumnConfig,
    title: "已分配",
    rowKey: "id",
    fetch: async (pageNo, pageSize) =>
      formatTask(
        await getTasksInOrg({
          taskType: ServiceType.REPAIR,
          page: pageNo,
          assigned: 1,
        }),
      ),
  },
];
