import { NameValuePair } from "./NameValuePair";

export enum TaskStatus {
  Open = 1,
  ToBeAccepted = 2,
  Accepted = 3,
  Rejected = 4,
  SolutionUnderReview = 5,
  Closed = 6,
  Processing = 7,
  ToBeRated = 8,
  Finished = 9,
}

export const TaskStatusDescriptions: NameValuePair = {
  [TaskStatus.Open]: "待分配",
  [TaskStatus.ToBeAccepted]: "分配待确认",
  [TaskStatus.Accepted]: "分配已确认",
  [TaskStatus.Rejected]: "分配已拒绝",
  [TaskStatus.SolutionUnderReview]: "等待客户确认",
  [TaskStatus.Closed]: "已关闭",
  [TaskStatus.Processing]: "维修中",
  [TaskStatus.ToBeRated]: "待评价",
  [TaskStatus.Finished]: "已完成",
};
