import { ColumnProps } from "antd/es/table";
import {
  IndexValuePair,
  TaskStatus,
  TaskStatusDescriptions,
  TaskWithTicket,
  Ticket,
  TicketStatus,
  TicketStatusDescriptions,
} from "../../models";
import { Link } from "react-router-dom";
import React from "react";
import { Tag } from "antd";

const TicketColors: IndexValuePair = {
  [TicketStatus.UnderReview]: "orange",
  [TicketStatus.SolutionUnderReview]: "orange",
  [TicketStatus.Approved]: "green",
  [TicketStatus.Processing]: "orange",
  [TicketStatus.Finished]: "green",
  [TicketStatus.ToBeRated]: "orange",
  [TicketStatus.Closed]: "grey",
  [TicketStatus.Rejected]: "red",
};
const TaskColors: IndexValuePair = {
  [TaskStatus.Open]: "orange",
  [TaskStatus.ToBeAccepted]: "orange",
  [TaskStatus.Accepted]: "green",
  [TaskStatus.Rejected]: "red",
  [TaskStatus.Closed]: "grey",
  [TaskStatus.Processing]: "orange",
  [TaskStatus.ToBeRated]: "orange",
  [TaskStatus.Finished]: "green",
};
export const DefaultTaskColumnConfig: ColumnProps<TaskWithTicket>[] = [
  {
    title: "维修单号",
    dataIndex: "ticket.ticketNumber",
    key: "ticket.ticketNumber",
    render: (text: string, task: TaskWithTicket) => {
      return <b>{task.ticket.ticketNumber}</b>;
    },
  },
  {
    title: "报修时间",
    dataIndex: "ticket.createTime",
    key: "ticket.createTime",
    render: (text: string, task: TaskWithTicket) => {
      return new Date(
        task.ticket.createTime > 9999999999 ? task.ticket.createTime : task.ticket.createTime * 1000,
      ).toLocaleString();
    },
  },
  {
    title: "店铺名称",
    dataIndex: "ticket.orgName",
    key: "ticket.orgName",
  },
  {
    title: "当前状态",
    dataIndex: "status",
    key: "status",
    render: (text: string, task: TaskWithTicket) => {
      return <Tag color={TaskColors[task.status]}>{TaskStatusDescriptions[task.status]}</Tag>;
    },
  },
  {
    title: "操作",
    key: "action",
    render: (text: string, task: TaskWithTicket) => {
      return <Link to={`/dashboard/task/${task.id}`}>查看</Link>;
    },
  },
];
export const DefaultTicketColumnConfig: ColumnProps<Ticket>[] = [
  {
    title: "维修单号",
    dataIndex: "ticketNumber",
    key: "ticketNumber",
    render: (text: string, ticket: Ticket) => {
      return <b>{ticket.ticketNumber}</b>;
    },
  },
  {
    title: "报修时间",
    dataIndex: "createTime",
    key: "createTime",
    render: (text: string, ticket: Ticket) => {
      return new Date(ticket.createTime > 9999999999 ? ticket.createTime : ticket.createTime * 1000).toLocaleString();
    },
  },
  {
    title: "报修类型",
    render: (text: string, ticket: Ticket) => {
      const issueGroup = (ticket.issues || []).map(issue => issue.group).filter(group => group !== undefined);
      return issueGroup.length > 0 ? issueGroup.join(", ") : "N/A";
    },
  },
  {
    title: "店铺号",
    dataIndex: "orgSerial",
    key: "orgSerial",
  },
  {
    title: "店铺名称",
    dataIndex: "orgName",
    key: "orgName",
  },
  {
    title: "渠道",
    dataIndex: "creator.organization.name",
    key: "creator.organization.name",
  },
  {
    title: "当前状态",
    dataIndex: "status",
    key: "status",
    render: (text: string, ticket: Ticket) => {
      return <Tag color={TicketColors[ticket.status]}>{TicketStatusDescriptions[ticket.status]}</Tag>;
    },
  },
  {
    title: "操作",
    key: "action",
    render: (text: string, ticket: Ticket) => {
      return <Link to={`/dashboard/ticket/${ticket.id}`}>查看</Link>;
    },
  },
];
