import React, { FunctionComponent } from "react";
import { Store } from "redux";
import { Persistor } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import ScrollToTop from "./components/scroll-to-top";

import Routes from "./routes";
import { ApplicationState } from "./store";

interface MainProps {
  store: Store<ApplicationState>;
  persistor: Persistor;
  history: History;
}

const Main: FunctionComponent<MainProps> = ({ store, persistor, history }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={zhCN}>
        <ConnectedRouter history={history}>
          <ScrollToTop />
          <Routes />
        </ConnectedRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

export default Main;
