import { css } from "@emotion/core";

export const BeforeTable = css`
  margin-bottom: 24px;
`;
export const TextEllipsis = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
