import React from "react";
import logo from "../../assets/logo.svg";
import home_log_img from "./images/home_logo.png";
import icon_draw from "./images/icon-draw.png";
import icon_tools from "./images/icon-tools.png";
import icon_wave from "./images/icon-wave.png";
import copyrights_logo from "./images/copyrights-logo.png";
import ss001 from "./images/ss001.png";
import ss002 from "./images/ss002.png";
import ss003 from "./images/ss003.png";
import ss004 from "./images/ss004.png";
import downloadBackground from "./images/download_bg.png";
import androidQR from "../../assets/android.png";
import iosQR from "../../assets/ios.png";
import { CustomerLoginUrl, VendorLoginUrl } from "../../config";
import { isMobile } from "react-device-detect";
import { preview } from "../../components/image-preview/ImagePreview";
import { Icon } from "antd";

export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: logo,
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        className: "header0-item",
        children: {
          href: CustomerLoginUrl,
          children: [{ children: "客户登录", name: "text" }],
        },
      },
      {
        name: "item1",
        className: "header0-item",
        children: {
          href: VendorLoginUrl,
          children: [{ children: "供应商登录", name: "text" }],
        },
      },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
};
export const Banner20DataSource = {
  wrapper: { className: "banner2" },
  BannerAnim: {
    children: [
      {
        name: "elem0",
        BannerElement: { className: "banner-user-elem" },
        page: { className: "home-page banner2-page" },
        textWrapper: { className: "banner2-text-wrapper" },
        bg: { className: "bg bg0" },
        // title: {
        //   className: "banner2-title",
        //   children: "Maintenance as Platform Service",
        // },
        title: {
          className: "banner2-title",
          children: home_log_img,
        },
        // content: {
        //   className: "banner2-content",
        //   children: "高效的维修工单系统",
        // },
        // button: { className: "banner2-button", children: "了解更多" },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "产品与服务" },
      { name: "subject", children: "PRODUCTS AND SERVICES" },
      { name: "hr" },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block line-vertical",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-sm-icon",
              children: icon_tools,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "统一工单平台",
            },
            { name: "content", children: "门店与供应商的一站式系统" },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block line-vertical",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-sm-icon",
              children: icon_draw,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "维修进度监控",
            },
            {
              name: "content",
              children: "实时追踪工单进度，方便快捷",
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block line-vertical",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-sm-icon",
              children: icon_wave,
            },
            {
              name: "title",
              className: "content0-block-title",
              children: "智能数据报表",
            },
            {
              name: "content",
              children: "洞悉历史数据，工单信息了若指掌",
            },
          ],
        },
      },
    ],
  },
};
export const Content01DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  page: { className: "home-page content1" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "软件特性" },
      { name: "subject", children: "SOFTWARE SPECIFICATION" },
      { name: "hr" },
    ],
  },
  childWrapper: {
    className: "content1-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content1-block",
        children: {
          className: "content1-block-item",
          children: {
            left: {
              children: [
                {
                  name: "title",
                  className: "content1-block-title",
                  children: "多平台接入系统",
                },
                { name: "content", children: "用户可通过网站、手机应用程序等多种渠道提交工单" },
              ],
            },
            right: {
              children: [
                {
                  name: "image",
                  className: "content1-block-icon",
                  children: ss001,
                },
              ],
            },
          },
        },
      },
      {
        name: "block1",
        className: "content1-block",
        children: {
          className: "content1-block-item",
          children: {
            left: {
              children: [
                {
                  name: "image",
                  className: "content1-block-icon",
                  children: ss002,
                },
              ],
            },
            right: {
              children: [
                {
                  name: "title",
                  className: "content1-block-title",
                  children: "多类型提醒工具",
                },
                { name: "content", children: "多种客服工具，实时提醒，保证极速处理问题" },
              ],
            },
          },
        },
      },
      {
        name: "block0",
        className: "content1-block",
        children: {
          className: "content1-block-item",
          children: {
            left: {
              children: [
                {
                  name: "title",
                  className: "content1-block-title",
                  children: "多层次审批机构",
                },
                { name: "content", children: "层次化工单审批结构，管理者全程掌握工单状态" },
              ],
            },
            right: {
              children: [
                {
                  name: "image",
                  className: "content1-block-icon",
                  children: ss003,
                },
              ],
            },
          },
        },
      },
      {
        name: "block1",
        className: "content1-block",
        children: {
          className: "content1-block-item",
          children: {
            left: {
              children: [
                {
                  name: "image",
                  className: "content1-block-icon",
                  children: ss004,
                },
              ],
            },
            right: {
              children: [
                {
                  name: "title",
                  className: "content1-block-title",
                  children: "多策略定义流程",
                },
                { name: "content", children: "个性化企业流程定制服务，满足不同企业业务需求" },
              ],
            },
          },
        },
      },
    ],
  },
};
export const Content02DataSource = {
  wrapper: { className: "home-page-wrapper content2-wrapper" },
  page: { className: "home-page content2" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [{ name: "title", children: "APP 下载" }, { name: "subject", children: "DOWNLOAD" }, { name: "hr" }],
  },
  childWrapper: {
    className: "content2-block-wrapper",
    background: {
      src: downloadBackground,
    },
    children: [
      { name: "title", children: "下载应用程序" },
      { name: "subject", children: "iPhone 和 Android" },
    ],
    buttons: [
      {
        name: "button",
        children: "APP STORE",
        icon: "apple",
        onClick: event => {
          if (isMobile) {
            // eslint-disable-next-line no-restricted-globals
            location.href = "https://apps.apple.com/us/app/id1500733246";
          } else {
            preview("下载iPhone程序", iosQR, <Icon type="apple" theme="filled" />);
          }
        },
      },
      {
        name: "button",
        children: "Android应用市场",
        icon: "android",
        onClick: event => {
          if (isMobile) {
            // eslint-disable-next-line no-restricted-globals
            location.href = "http://download.vigoroup.com/app/android/maps.apk";
          } else {
            preview("下载Android程序", androidQR, <Icon type="android" theme="filled" />);
          }
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: "home-page-wrapper footer0-wrapper" },
  OverPack: { className: "home-page footer0", playScale: 0.05 },
  copyright: {
    className: "copyright",
    children: (
      <>
        <div className={"copyrights_logo"}>
          <img src={copyrights_logo} alt={"copyrights"} />
        </div>
        <div>©2015-2020 Vigorous All Rights Reserved （备案号：沪ICP备16016121号-1）</div>
      </>
    ),
  },
};
