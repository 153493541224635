import { Ticket } from "../../models";
import SearchForm from "../customer/search-form";
import React from "react";
import { ListViewConfig } from "./list-view-config";
import { formatTicket } from "./formatters";
import { DefaultTicketColumnConfig } from "./DefaultColumnConfig";
import { getMyTickets } from "../../api/ticket";

export const TicketQueryUnderBrandListViewConfig: ListViewConfig<Ticket>[] = [
  {
    key: "ticket-query-brand",
    columns: DefaultTicketColumnConfig,
    title: "搜索",
    rowKey: "id",
    fetch: async (pageNo, pageSize, params) =>
      formatTicket(
        await getMyTickets({
          page: pageNo,
          ...params,
        }),
      ),
    navBar: props => <SearchForm filterChange={props.filterChange} />,
  },
];
