export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    md: { span: 16 },
    lg: { span: 14 },
    xl: { span: 10 },
  },
};
export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 24,
    },
    sm: {
      span: 18,
      offset: 6,
    },
    md: {
      span: 16,
      offset: 6,
    },
    lg: {
      span: 14,
      offset: 6,
    },
    xl: {
      span: 10,
      offset: 4,
    },
  },
};
export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 6 },
    md: { span: 16, offset: 6 },
    lg: { span: 14, offset: 6 },
    xl: { span: 10, offset: 4 },
  },
};

export const dynamicFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    md: { span: 18 },
    lg: { span: 18 },
    xl: { span: 20 },
  },
};
