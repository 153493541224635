import { CustomerType, CustomerTypeDescriptions, SelectOption } from "./index";

export const CustomerTypeOptions: SelectOption<number>[] = [
  {
    label: CustomerTypeDescriptions[CustomerType.DirectSaleStore],
    value: CustomerType.DirectSaleStore,
  },
  {
    label: CustomerTypeDescriptions[CustomerType.Channel],
    value: CustomerType.Channel,
  },
  {
    label: CustomerTypeDescriptions[CustomerType.Headquarters],
    value: CustomerType.Headquarters,
  },
];
