import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { Layout } from "antd";

export const Footer: FunctionComponent = () => (
  <DashBoardFooter>©2015-2020 Vigorous All Rights Reserved （备案号：沪ICP备16016121号-1）</DashBoardFooter>
);
const DashBoardFooter = styled(Layout.Footer)`
  text-align: center;
`;
