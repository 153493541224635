import React from "react";
import { Form, Input, Modal } from "antd";
import { FormComponentProps } from "antd/es/form";

export interface RejectFinishFormProps extends FormComponentProps {
  comment: string;
}

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

type AllProps = RejectFinishFormProps & OwnProps;

class RejectFinishForm extends React.Component<AllProps> {
  static defaultProps = {
    comment: "",
    visible: false,
    onCancel: () => {},
    onSubmit: () => {},
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
  };

  render() {
    const { visible, onCancel, onSubmit, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal visible={visible} onCancel={onCancel} onOk={onSubmit}>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item label="未完成的原因">
            {getFieldDecorator("comment", {
              rules: [{ required: true, message: "请描述问题" }],
            })(<Input.TextArea rows={4} placeholder="请描述未完成的原因" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedRejectFinishForm = Form.create<RejectFinishFormProps>({
  name: "reject_finish",
})(RejectFinishForm);

export default WrappedRejectFinishForm;
