import React from "react";
import { Form, Input, Modal } from "antd";
import { FormComponentProps } from "antd/es/form";

export interface RejectAssignmentFormProps extends FormComponentProps {
  reason?: string;
}

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

type AllProps = RejectAssignmentFormProps & OwnProps;

class RejectAssignmentForm extends React.Component<AllProps> {
  static defaultProps = {
    visible: false,
    onCancel: () => {},
    onSubmit: () => {},
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
  };

  render() {
    const { visible, onCancel, onSubmit, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal visible={visible} onCancel={onCancel} onOk={onSubmit}>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item label="拒绝原因">
            {getFieldDecorator("reason", {
              rules: [{ required: true, message: "请阐明原因" }],
            })(<Input.TextArea rows={4} placeholder="请描述具体的拒绝原因" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedRejectAssignmentForm = Form.create<RejectAssignmentFormProps>({
  name: "assign_task",
})(RejectAssignmentForm);

export default WrappedRejectAssignmentForm;
