import React from "react";
import { css } from "@emotion/core";
import { Form, Modal, Table } from "antd";

import { getAttachmentUrl } from "../../utils/attachment";
import { formItemLayout } from "../../styles/form";
import * as Preview from "../../styles/preview";
import { Attachment, Task, TaskDetail, TaskDetailTypes } from "../../models";

interface OwnProps {
  task: Task;
  showPrice: boolean;
  showDetail: boolean;
}

interface State {
  readonly previewVisible: boolean;
  readonly previewImage?: string;
  readonly previewTitle?: string;
}

const getMaterialColumns = (showPrice: boolean) => {
  if (showPrice) {
    return [
      {
        title: "品名",
        dataIndex: "name",
      },
      {
        title: "数量",
        dataIndex: "num",
      },
      {
        title: "单价",
        dataIndex: "price",
        render: (text: any, taskDetail: TaskDetail) => (taskDetail.price / 100).toFixed(2),
      },
    ];
  } else {
    return [
      {
        title: "品名",
        dataIndex: "name",
      },
      {
        title: "数量",
        dataIndex: "num",
      },
    ];
  }
};

const getLaborsColumns = (showPrice: boolean) => {
  if (showPrice) {
    return [
      {
        title: "维修方式",
        dataIndex: "name",
      },
      {
        title: "维修工时",
        dataIndex: "num",
      },
      {
        title: "单价",
        dataIndex: "price",
        render: (text: any, taskDetail: TaskDetail) => (taskDetail.price / 100).toFixed(2),
      },
    ];
  } else {
    return [
      {
        title: "维修方式",
        dataIndex: "name",
      },
      {
        title: "维修工时",
        dataIndex: "num",
      },
    ];
  }
};

const getOtherColumns = (showPrice: boolean) => {
  if (showPrice) {
    return [
      {
        title: "名字",
        dataIndex: "name",
      },
      {
        title: "数量",
        dataIndex: "num",
      },
      {
        title: "单价",
        dataIndex: "price",
        render: (text: any, taskDetail: TaskDetail) => (taskDetail.price / 100).toFixed(2),
      },
    ];
  } else {
    return [
      {
        title: "名字",
        dataIndex: "name",
      },
      {
        title: "数量",
        dataIndex: "num",
      },
    ];
  }
};

// FIXME: do not use any?
const getTotalPrice = (items: any[]): number => {
  return items.map((item: any): number => item.num * (item.price / 100)).reduce((a: number, b: number) => a + b, 0);
};

class ReadonlyRepairTask extends React.Component<OwnProps, State> {
  state = {
    previewVisible: false,
    previewImage: undefined,
    previewTitle: undefined,
  };

  static defaultProps = {
    showPrice: false,
    showDetail: true,
  };

  handleCancel = () =>
    this.setState({
      previewVisible: false,
      previewImage: undefined,
      previewTitle: undefined,
    });

  handlePreview = (attachment: Attachment) => {
    this.setState({
      previewVisible: true,
      previewImage: getAttachmentUrl(attachment.attachmentId),
      previewTitle: attachment.attachmentName,
    });
  };

  render() {
    const { task, showPrice, showDetail } = this.props;
    const materials: TaskDetail[] = (task.taskDetailList || []).filter(
      taskDetail => taskDetail.type === TaskDetailTypes.MATERIALS,
    );
    const repairs: TaskDetail[] = (task.taskDetailList || []).filter(
      taskDetail => taskDetail.type === TaskDetailTypes.REPAIRS,
    );
    const others: TaskDetail[] = (task.taskDetailList || []).filter(
      taskDetail => taskDetail.type === TaskDetailTypes.OTHERS,
    );
    const totalMaterialPrice = getTotalPrice(materials);
    const totalLaborPrice = getTotalPrice(repairs);
    const totalOtherPrice = getTotalPrice(others);
    return (
      <Form {...formItemLayout} css={NoMargin}>
        <Form.Item label="任务内容">
          维修安装 ( <b>{task.name}</b> )
        </Form.Item>
        <Form.Item label="维修人员信息">{task.assignDesc}</Form.Item>
        <Form.Item label="实际到店日期">{task.serviceTime}</Form.Item>
        {showDetail ? (
          <>
            <Form.Item label="材料">
              <Table
                css={TableMargin}
                columns={getMaterialColumns(showPrice)}
                dataSource={materials}
                footer={() => (showPrice ? `合计：${totalMaterialPrice.toFixed(2)}` : null)}
                size="small"
                pagination={false}
                rowKey={(material, index) => index.toString()}
              />
            </Form.Item>

            <Form.Item label="维修方案">
              <Table
                css={TableMargin}
                columns={getLaborsColumns(showPrice)}
                dataSource={repairs}
                footer={() => (showPrice ? `合计：${totalLaborPrice.toFixed(2)}` : null)}
                size="small"
                pagination={false}
                rowKey={(labors, index) => index.toString()}
              />
            </Form.Item>
            <Form.Item label="其他费用">
              <Table
                columns={getOtherColumns(showPrice)}
                dataSource={others}
                footer={() => (showPrice ? `合计：${totalOtherPrice.toFixed(2)}` : null)}
                size="small"
                pagination={false}
                rowKey={(other, index) => index.toString()}
              />
            </Form.Item>
            <Form.Item label="其他附件">
              {task.attachments?.length === 0
                ? "无"
                : task.attachments?.map(attachment => (
                    <div css={Preview.PicCardContainer} key={attachment.attachmentId}>
                      <img
                        alt={attachment.attachmentName}
                        css={Preview.PicCardImg}
                        onClick={() => this.handlePreview(attachment)}
                        src={getAttachmentUrl(attachment.attachmentId)}
                      />
                    </div>
                  ))}
            </Form.Item>
          </>
        ) : null}
        <Form.Item label="备注">{task.comment}</Form.Item>
        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={this.handleCancel}>
          <img alt={this.state.previewTitle} style={{ width: "100%" }} src={this.state.previewImage} />
        </Modal>
      </Form>
    );
  }
}

export default ReadonlyRepairTask;

const NoMargin = css`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const TableMargin = css`
  margin-bottom: 16px;
`;
