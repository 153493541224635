import { TaskWithTicket } from "../../models";
import { getMyTasks } from "../../api/task";
import { ListViewConfig } from "./list-view-config";
import { formatTask } from "./formatters";
import { DefaultTaskColumnConfig } from "./DefaultColumnConfig";

export const RepairStaffListViewConfig: ListViewConfig<TaskWithTicket>[] = [
  {
    key: "my-repair-list",
    columns: DefaultTaskColumnConfig,
    title: "我的任务",
    rowKey: "id",
    fetch: async (pageNo, pageSize) => formatTask(await getMyTasks(pageNo)),
  },
];
