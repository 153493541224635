/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";

import Nav0 from "./Nav0";
import Banner2 from "./Banner2";
import Content0 from "./Content0";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Footer0 from "./Footer0";
import Point from "./Point";
import {
  Banner20DataSource,
  Content00DataSource,
  Content01DataSource,
  Content02DataSource,
  Footer00DataSource,
  Nav00DataSource,
} from "./data.source";
import "./less/antMotionStyle.less";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav0 id="Nav0_0" key="Nav0_0" dataSource={Nav00DataSource} isMobile={this.state.isMobile} />,
      <Banner2 id="Banner2_0" key="Banner2_0" dataSource={Banner20DataSource} isMobile={this.state.isMobile} />,
      <Content0 id="Content0_0" key="Content0_0" dataSource={Content00DataSource} isMobile={this.state.isMobile} />,
      <Content1 id="Content0_1" key="Content0_1" dataSource={Content01DataSource} isMobile={this.state.isMobile} />,
      <Content2 id="Content0_2" key="Content0_2" dataSource={Content02DataSource} isMobile={this.state.isMobile} />,
      <Footer0 id="Footer0_0" key="Footer0_0" dataSource={Footer00DataSource} isMobile={this.state.isMobile} />, // 导航和页尾不进入锚点区，如果需要，自行添加;
      <Point key="list" data={["Nav0_0", "Banner2_0", "Content0_0", "Content0_1", "Content0_2"]} />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={d => {
          this.dom = d;
        }}>
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
