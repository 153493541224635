import React from "react";
import { css } from "@emotion/core";
import { Button, Col, Form, Icon, Input, InputNumber, Row, Upload } from "antd";
import { FormComponentProps } from "antd/es/form";

import { normalizeFile } from "../../../../utils/form";
import { checkIsImageBeforeUpload } from "../../../../utils/attachment";
import { getEndpoint } from "../../../../config";

import * as FormLayout from "../../../../styles/form";

export interface ExpressFormProps extends FormComponentProps {
  materialKeys: number[];
  materialName: string[];
  materialNumber: number[];
  materialUnit: string[];
  materialPrice: number[];
  expressKeys: number[];
  expressCompany: string[];
  expressTracking: string[];
  comment?: string;
  attachments: any[];
}

interface OwnProps {
  onSubmit: (e: React.FormEvent) => void;
}

type AllProps = ExpressFormProps & OwnProps;

interface State {
  readonly materialId: number;
  readonly expressId: number;
}

class ExpressForm extends React.Component<AllProps, State> {
  state = {
    materialId: 1,
    expressId: 1,
  };

  static defaultProps = {
    materialKeys: [],
    materialName: [],
    materialNumber: [],
    materialUnit: [],
    materialPrice: [],
    expressKeys: [],
    expressCompany: [],
    expressTracking: [],
    attachments: [],
    onSubmit: (e: React.FormEvent) => {},
  };

  removeMaterial = (k: number) => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("materialKeys");
    if (keys.length === 0) {
      return;
    }

    setFieldsValue({
      materialKeys: keys.filter((key: number) => key !== k),
    });
  };

  addMaterial = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("materialKeys");
    const nextKeys = [...keys, this.state.materialId];
    this.setState({
      materialId: this.state.materialId + 1,
    });

    setFieldsValue({
      materialKeys: nextKeys,
    });
  };

  removeExpress = (k: number) => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("expressKeys");
    if (keys.length === 0) {
      return;
    }

    setFieldsValue({
      expressKeys: keys.filter((key: number) => key !== k),
    });
  };

  addExpress = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("expressKeys");
    const nextKeys = [...keys, this.state.expressId];
    this.setState({
      expressId: this.state.expressId + 1,
    });

    setFieldsValue({
      expressKeys: nextKeys,
    });
  };

  render() {
    const { onSubmit, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    getFieldDecorator("materialKeys", { initialValue: [] });
    const materialKeys = getFieldValue("materialKeys");
    const materialItems = materialKeys.map((k: number, index: number) => (
      <Form.Item
        key={k}
        label={
          <span>
            <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.removeMaterial(k)} /> 材料
            {index + 1}
          </span>
        }
        required={false}
        help={null}
        hasFeedback={false}
        style={{ marginBottom: 0 }}
        {...FormLayout.dynamicFormItemLayout}>
        <Row>
          <Col span={10}>
            <Form.Item>
              {getFieldDecorator(`materialName[${k}]`, {
                rules: [{ required: true, message: "请输入品名" }],
              })(<Input placeholder="品名" />)}
            </Form.Item>
          </Col>

          <Col span={6} offset={2}>
            <Form.Item label="数量" css={InlineFormItem}>
              {getFieldDecorator(`materialNumber[${k}]`, {
                initialValue: 1,
                rules: [{ required: true, message: "请输入数量" }],
              })(<InputNumber min={1} />)}
            </Form.Item>
          </Col>

          {getFieldDecorator(`materialUnit[${k}]`, {
            initialValue: "",
          })(<Input hidden={true} />)}

          <Col span={6}>
            <Form.Item label="单价" css={InlineFormItem}>
              {getFieldDecorator(`materialPrice[${k}]`, {
                rules: [{ required: false, message: "请输入单价" }],
              })(<InputNumber placeholder="单价" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    ));

    getFieldDecorator("expressKeys", { initialValue: [] });
    const expressKeys = getFieldValue("expressKeys");
    const expressItems = expressKeys.map((k: number, index: number) => (
      <div key={k}>
        <Form.Item
          label={
            <span>
              <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.removeExpress(k)} />{" "}
              快递公司{index + 1}
            </span>
          }
          required={false}
          key={k}>
          {getFieldDecorator(`expressCompany[${k}]`, {
            rules: [{ required: true, message: "请输入快递公司" }],
          })(<Input placeholder="快递公司" />)}
        </Form.Item>

        <Form.Item label="单号">
          {getFieldDecorator(`expressTracking[${k}]`, {
            rules: [{ required: true, message: "请输入快递单号" }],
          })(<Input placeholder="快递单号" />)}
        </Form.Item>
      </div>
    ));

    return (
      <Form {...FormLayout.formItemLayout} onSubmit={onSubmit}>
        {materialItems}
        <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
          <Button type="dashed" onClick={this.addMaterial}>
            <Icon type="plus" /> 添加材料
          </Button>
        </Form.Item>

        {expressItems}
        <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
          <Button type="dashed" onClick={this.addExpress}>
            <Icon type="plus" /> 添加快递信息
          </Button>
        </Form.Item>

        <Form.Item label="备注">
          {getFieldDecorator("comment")(<Input.TextArea placeholder="请输入备注" autoSize={{ minRows: 5 }} />)}
        </Form.Item>

        <Form.Item label="其他附件">
          {getFieldDecorator("attachments", {
            valuePropName: "fileList",
            getValueFromEvent: normalizeFile,
          })(
            <Upload.Dragger
              name="attachment"
              action={`${getEndpoint()}/attachment`}
              accept="image/*"
              beforeUpload={checkIsImageBeforeUpload}
              multiple>
              <Icon type="upload" /> 点击上传其他附件
            </Upload.Dragger>,
          )}
        </Form.Item>

        <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedExpressForm = Form.create<ExpressFormProps>({
  name: "express_form",
})(ExpressForm);

export default WrappedExpressForm;

const InlineFormItem = css`
  display: flex;
`;
