import { ServiceType, TaskWithTicket } from "../../models";
import { getTasksInOrg } from "../../api/task";
import { ListViewConfig } from "./list-view-config";
import { formatTask } from "./formatters";
import { DefaultTaskColumnConfig } from "./DefaultColumnConfig";

export const ExpressListViewConfig: ListViewConfig<TaskWithTicket>[] = [
  {
    key: "express-list",
    columns: DefaultTaskColumnConfig,
    title: "物料替换单",
    rowKey: "id",
    fetch: async (pageNo, pageSize) =>
      formatTask(
        await getTasksInOrg({
          taskType: ServiceType.EXPRESS,
          page: pageNo,
        }),
      ),
  },
];
