import { AttachmentEndpoint } from "../config";
import { message } from "antd";
import { RcFile } from "antd/lib/upload";

export const getAttachmentUrl = (attachmentId: number): string => {
  return `${AttachmentEndpoint}${attachmentId}`;
};

const isImage = (filetype: string): boolean => {
  return ["image/jpeg", "image/png", "image/bmp", "image/gif", "image/webp"].includes(filetype);
};

export const checkIsImageBeforeUpload = (file: RcFile, fileList: RcFile[]): boolean => {
  if (isImage(file.type)) {
    return true;
  } else {
    message.error("只能上传图片");
    return false;
  }
};
