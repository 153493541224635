import React from "react";
import { connect } from "react-redux";
import { Card, Divider, Form } from "antd";

import { ApplicationState } from "../../../store";
import { Auth } from "../../../store/auth/types";
import { UserType } from "../../../store/userinfo/types";

import { ticketStatusToText } from "../../../utils/ticket";
import { formItemLayout } from "../../../styles/form";

import ReadonlyTicket from "../../../components/readonly-ticket";
import ReadonlyExpressTask from "../../../components/readonly-express-task";
import ReadonlyRepairTask from "../../../components/readonly-repair-task";
import Timeline from "../../../components/timeline";
import { ServiceType, Task, Ticket } from "../../../models";

interface OwnProps {
  ticket: Ticket;
  refreshTicketFn: (ticket?: Ticket) => void | Promise<void>;
}

interface PropsFromState {
  auth?: Auth;
}

type AllProps = OwnProps & PropsFromState;

class DefaultTicketDetailWithTasks extends React.Component<AllProps> {
  getTaskComponent = (task: Task) => {
    const { auth } = this.props;
    if (!auth) {
      return;
    }
    const showPrice = auth.userLevel === UserType.ADMIN ? true : false;
    if (task.type === ServiceType.EXPRESS) {
      return <ReadonlyExpressTask key={task.id} task={task} showPrice={showPrice} />;
    } else if (task.type === ServiceType.REPAIR) {
      return <ReadonlyRepairTask key={task.id} task={task} showPrice={showPrice} />;
    } else {
      return null;
    }
  };

  render() {
    const { ticket, refreshTicketFn } = this.props;
    const tasks = ticket.tasks;
    return (
      <Card>
        <ReadonlyTicket ticket={ticket} />

        {tasks.map(task => {
          if (!task) {
            return null;
          }

          return (
            <div key={task.id}>
              <Divider />
              {this.getTaskComponent(task)}
            </div>
          );
        })}
        <Divider />
        <Form.Item label="工单状态" {...formItemLayout}>
          {ticketStatusToText(ticket.status)}
        </Form.Item>
        <Divider />
        <Timeline ticket={ticket} refreshTicketFn={refreshTicketFn} />
      </Card>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  auth: state.auth.data,
});

export default connect(mapStateToProps)(DefaultTicketDetailWithTasks);
