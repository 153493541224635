import React from "react";
import {Checkbox, Form, Input, Modal} from "antd";
import {FormComponentProps} from "antd/es/form";
import {CheckboxOptionType, CheckboxValueType} from "antd/es/checkbox/Group";
import {getOrgsByType} from "../../../../api/org";
import isArray from "lodash/isArray";
import {
  Organization,
  OrgType,
  ServiceTypeDescriptions,
  Status
} from "../../../../models";
import {AvailableServiceTypeOptions} from "../../../../models/ServiceTypeOptions";
import {OrgRange} from "../../../../models/OrgRange";

export interface ApproveTicketFormProps extends FormComponentProps {
  services: string[];
  auditComment?: string;

  [serviceType: string]: any;
}

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (organizations: Organization[], auditComment?: string) => void;
}

type AllProps = ApproveTicketFormProps & OwnProps;

interface OrgsByType {
  [serviceType: string]: CheckboxOptionType[];
}

interface State {
  loading: boolean;
  orgs: Organization[];
  orgsByType: OrgsByType;
  selectedServiceTypes?: CheckboxValueType[];
}

class ApproveTicketForm extends React.Component<AllProps, State> {
  state = {
    loading: true,
    orgs: [],
    orgsByType: {},
    selectedServiceTypes: [],
  };

  static defaultProps = {
    services: [],
    repair: [],
    express: [],
    visible: false,
    onCancel: () => {},
    onSubmit: (organizations: Organization[]) => {},
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.setState({
      loading: true,
      orgsByType: {},
      selectedServiceTypes: [],
    });
  };

  handleChange = (value: CheckboxValueType[]) => {
    this.setState({ selectedServiceTypes: value });
  };

  async componentDidMount(): Promise<void> {
    try {
      let orgs: Organization[] = await getOrgsByType(OrgType.VENDOR, Status.Active, OrgRange.Self);
      if (orgs && isArray(orgs)) {
        let orgsByType: OrgsByType = {};
        orgs.forEach((org: Organization) => {
          if (!orgsByType[`${org.service}`]) {
            orgsByType[`${org.service}`] = [];
          }
          orgsByType[`${org.service}`].push({ label: org.name, value: org.id });
        });
        this.setState({ orgsByType, orgs });
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleModalOk = async () => {
    const { onSubmit, form } = this.props;
    form.validateFields(async (err: any, values: ApproveTicketFormProps) => {
      if (err) {
        return;
      }
      const { orgs } = this.state;
      const { services = [], auditComment } = values;
      let orgIds: number[] = [];
      services.forEach(service => {
        orgIds = orgIds.concat(values[service] as number[]);
      });
      let selectedOrgs: Organization[] = orgs.filter((org: Organization) => orgIds.includes(org.id));
      await onSubmit(selectedOrgs, auditComment);
    });
  };

  render() {
    const { visible, onCancel, form } = this.props;
    const { selectedServiceTypes, orgsByType } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Modal visible={visible} onCancel={onCancel} onOk={this.handleModalOk}>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item label="请选择服务类型">
            {getFieldDecorator("services", {
              rules: [{ required: true, message: "请选择至少一项服务" }],
            })(<Checkbox.Group options={AvailableServiceTypeOptions} onChange={this.handleChange} />)}
          </Form.Item>
          {selectedServiceTypes.map(serviceType => {
            let serviceOption = AvailableServiceTypeOptions.filter(option => option.value === serviceType)[0];
            if (!serviceOption) return null;
            return (
              <Form.Item key={serviceType} label={serviceOption.label}>
                {getFieldDecorator(`${serviceType}`, {
                  rules: [
                    {
                      required: true,
                      message: `请选择提供${ServiceTypeDescriptions[serviceType]}的供应商`,
                    },
                  ],
                })(<Checkbox.Group options={orgsByType[serviceType]} />)}
              </Form.Item>
            );
          })}
          <Form.Item label="审批批注">
            {getFieldDecorator("auditComment")(
              <Input.TextArea placeholder="请输入审批批注" autoSize={{ minRows: 5 }} />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedApproveTicketForm = Form.create<ApproveTicketFormProps>({
  name: "approve_ticket",
})(ApproveTicketForm);

export default WrappedApproveTicketForm;
