import { ActionType } from "typesafe-actions";
import { TaskActionTypes, TaskRecord, TaskState } from "./types";
import * as TaskActions from "./actions";
import union from "lodash/union";

export const initialState: TaskState = {
  allIds: [],
  byId: {},
};

const taskReducer = (state = initialState, action: ActionType<typeof TaskActions>): TaskState => {
  switch (action.type) {
    case TaskActionTypes.ADD: {
      return {
        ...state,
        allIds: union(state.allIds, [action.payload.id]),
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    }
    case TaskActionTypes.ADD_RANGE: {
      const allIds: number[] = action.payload.map(task => task.id);
      const byId: TaskRecord = action.payload.reduce((obj, task) => {
        obj[task.id] = task;
        return obj;
      }, {} as TaskRecord);
      return {
        ...state,
        allIds: union(state.allIds, allIds),
        byId: { ...state.byId, ...byId },
      };
    }
    default:
      return state;
  }
};

export default taskReducer;
