import { css } from "@emotion/core";

export const PicCardContainer = css`
  position: relative;
  float: left;
  width: 118px;
  height: 118px;
  margin: 0px 8px 8px 0px;
  padding: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(217, 217, 217);
  border-image: initial;
  border-radius: 4px;
`;

export const PicCardImg = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`;
