export enum OrgType {
  CUSTOMER = 1,
  VENDOR = 2,
  PLATFORM = 3,
}

export const OrgTypeDescriptions: { [type: number]: string } = {
  [OrgType.CUSTOMER]: "客户",
  [OrgType.VENDOR]: "供应商",
  [OrgType.PLATFORM]: "平台",
};
