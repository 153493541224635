import React, { FunctionComponent, Suspense, useCallback, useEffect } from "react";
import { Footer, Header, Sidebar } from "./layout";
import styled from "@emotion/styled";
import { Layout } from "antd";
import { Route, Switch, useRouteMatch } from "react-router";
import Loading from "../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequest } from "../store/userinfo/actions";
import { ApplicationState } from "../store";
import { UserInfo } from "../store/userinfo/types";
import { OrgType } from "../models";
import { findNavigator as findVendorNavigator } from "./vendor/navigators";
import { findNavigator as findCustomerNavigator } from "./customer/navigators";
import { findNavigator as findPlatformNavigator } from "./platform/navigators";
import { getRouters as getCustomerRouters } from "./customer/routers";
import { getRouters as getVendorRouters } from "./vendor/routers";
import { getRouters as getPlatformRouters } from "./platform/routers";
import { getRouters as getCommonRouters } from "./routers";
import NotFound from "../components/not-found";

/**
 * 查询配置的导航菜单和路由器
 * @param userInfo
 * @param prefix
 */
function findNavigators(userInfo: UserInfo | undefined, prefix: string) {
  if (userInfo?.orgType === OrgType.VENDOR) {
    return findVendorNavigator(userInfo?.organization.service, userInfo?.level, prefix);
  } else if (userInfo?.orgType === OrgType.CUSTOMER) {
    return findCustomerNavigator(userInfo?.level, prefix);
  } else if (userInfo?.orgType === OrgType.PLATFORM) {
    return findPlatformNavigator(userInfo?.level, prefix);
  }
}

/**
 * Dashboard容器
 * @param match
 * @constructor
 */
export const Dashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const fetchUserInfo: any = useCallback(() => dispatch(fetchRequest()), [dispatch]);
  const match = useRouteMatch();
  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);
  const userInfo = useSelector<ApplicationState, UserInfo | undefined>(state => state.userInfo.data);
  const navs = findNavigators(userInfo, match.url);
  return (
    <DashboardLayout>
      <Sidebar menuConfigs={navs} />
      <Content>
        <Header />
        <Main>
          <Switch>
            {navs
              ?.filter(i => i.isRouter)
              .map(i => (
                <Route
                  key={i.url}
                  exact
                  path={i.url}
                  component={() => {
                    if (!i.component) {
                      return null;
                    }
                    const NavCmp = React.lazy(i.component);
                    return (
                      <Suspense fallback={<Loading />}>
                        <NavCmp />
                      </Suspense>
                    );
                  }}
                />
              ))}
            {userInfo?.orgType === OrgType.CUSTOMER ? getCustomerRouters(match.path) : null}
            {userInfo?.orgType === OrgType.VENDOR ? getVendorRouters(match.path) : null}
            {userInfo?.orgType === OrgType.PLATFORM ? getPlatformRouters(match.path) : null}
            {getCommonRouters(match.path)}
            <Route component={NotFound} />
          </Switch>
        </Main>
        <Footer />
      </Content>
    </DashboardLayout>
  );
};
const DashboardLayout = styled(Layout)`
  min-height: 100vh;
`;
const Content = styled(Layout)`
  margin-left: 200px;
`;
const Main = styled(Layout.Content)`
  margin: 24px;
`;
