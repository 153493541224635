import { Ticket, TicketStatus } from "../../models";
import { getMyTickets } from "../../api/ticket";
import { ListViewConfig } from "./list-view-config";
import { formatTicket } from "./formatters";
import { DefaultTicketColumnConfig } from "./DefaultColumnConfig";

export const CustomerAdminListViewConfig: ListViewConfig<Ticket>[] = [
  {
    key: "pending-list",
    columns: DefaultTicketColumnConfig,
    title: "报单等待审批",
    rowKey: "id",
    fetch: async (pageNo, pageSize, params) =>
      formatTicket(
        await getMyTickets({
          page: pageNo,
          priority_first: 1,
          status: TicketStatus.UnderReview,
          ...params,
        }),
      ),
  },
];
