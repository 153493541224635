import React from "react";
import { Form, Input, Modal, Select } from "antd";
import { FormComponentProps } from "antd/es/form";

const { Option } = Select;

// TODO 将来作为可配置
const CommonReason = ["信息不完整，问题描述不清晰", "该物料不在乐高负责的维修范围内"];

export interface RejectTicketFormProps extends FormComponentProps {
  reason: string;
  other?: string;
}

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

type AllProps = RejectTicketFormProps & OwnProps;

interface State {
  readonly otherReason: boolean;
}

class RejectTicketForm extends React.Component<AllProps, State> {
  state = {
    otherReason: false,
  };

  static defaultProps = {
    reason: "",
    visible: false,
    onCancel: () => {},
    onSubmit: () => {},
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.setState({ otherReason: false });
  };

  getReason = () => {
    const { form } = this.props;
    if (this.state.otherReason) {
      return form.getFieldValue("other");
    } else {
      return form.getFieldValue("reason");
    }
  };

  handleChange = (value: string) => {
    if (value === "other") {
      this.setState({ otherReason: true });
    } else {
      this.setState({ otherReason: false });
    }
  };

  render() {
    const { visible, onCancel, onSubmit, form } = this.props;
    const { getFieldDecorator } = form;

    const getOtherReasonInput = () => (
      <Form.Item>
        {getFieldDecorator("other", {
          rules: [{ required: true, message: "请描述问题" }],
        })(<Input.TextArea rows={4} placeholder="请描述具体的拒绝原因" />)}
      </Form.Item>
    );

    return (
      <Modal visible={visible} onCancel={onCancel} onOk={onSubmit}>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item label="拒绝原因">
            {getFieldDecorator("reason", {
              rules: [{ required: true, message: "请选择原因" }],
            })(
              <Select onChange={this.handleChange} placeholder="请选择原因">
                {CommonReason.map(reason => (
                  <Option key={reason} value={reason}>
                    {reason}
                  </Option>
                ))}
                <Option value="other">其他原因</Option>
              </Select>,
            )}
          </Form.Item>
          {this.state.otherReason ? getOtherReasonInput() : null}
        </Form>
      </Modal>
    );
  }
}

const WrappedRejectTicketForm = Form.create<RejectTicketFormProps>({
  name: "reject_ticket",
})(RejectTicketForm);

export default WrappedRejectTicketForm;
