import React, { FunctionComponent } from "react";
import { Card, Divider, message } from "antd";

import { addTaskSolution } from "../../../../api/task";
import { filesToAttachments } from "../../../../utils/form";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import ExpressForm, { ExpressFormProps } from "./express-form";
import { TaskDetailTypes } from "../../../../models";
import { TaskComponentProps } from "../TaskComponentProps";
import TimelineComponent from "../../../../components/timeline";

const ExpressSolution: FunctionComponent<TaskComponentProps> = ({ ticket, task, refreshTaskFn, refreshTicketFn }) => {
  let expressFormRef: any;
  const saveExpressFormRef = (formRef: any) => {
    expressFormRef = formRef;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { form } = expressFormRef.props;
    form.validateFields(async (err: any, values: ExpressFormProps) => {
      if (err) {
        return;
      }
      // merge material info
      const { materialKeys, materialName, materialNumber, materialUnit, materialPrice } = values;
      const repairDetails = materialKeys.map(k => ({
        name: materialName[k],
        num: materialNumber[k],
        unit: materialUnit[k],
        price: Math.round((materialPrice[k] || 0) * 100),
        type: TaskDetailTypes.EXPRESS_MATERIALS,
      }));

      // merge express info
      const { expressKeys, expressCompany, expressTracking } = values;
      const expressList = expressKeys.map(k => ({
        company: expressCompany[k],
        tracking: expressTracking[k],
      }));

      try {
        const payload = {
          repairDetails,
          expressList,
          attachments: filesToAttachments(values.attachments),
          comment: values.comment,
        };
        const res = await addTaskSolution(task.id, payload);
        // const res = await setTaskStatus(task.id, TaskStatus.ToBeRated);
        await refreshTaskFn(res);
      } catch (err) {
        message.error(err.message);
      }
    });
  };

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      <Divider />
      <ExpressForm wrappedComponentRef={saveExpressFormRef} onSubmit={handleSubmit} />
      <Divider />
      <TimelineComponent ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};

export default ExpressSolution;
