import { ActionType } from "typesafe-actions";
import { IssueActionTypes, IssueState } from "./types";
import * as IssueActions from "./actions";

export const initialState: IssueState = [];

const issueReducer = (state = initialState, action: ActionType<typeof IssueActions>): IssueState => {
  switch (action.type) {
    case IssueActionTypes.SET:
      return action.payload;
    case IssueActionTypes.ADD_GROUP:
      return [...state, action.payload];
    case IssueActionTypes.REMOVE_GROUP:
      return state.filter(group => group.id !== action.payload);
    case IssueActionTypes.ADD_ISSUE: {
      return state.map(group => {
        if (group.id !== action.payload.groupId) {
          return group;
        }

        return {
          ...group,
          items: [...group.items, action.payload.issue],
        };
      });
    }
    case IssueActionTypes.REMOVE_ISSUE: {
      return state.map(group => {
        if (group.id !== action.payload.groupId) {
          return group;
        }

        return {
          ...group,
          items: group.items.filter(issue => issue.id !== action.payload.issueId),
        };
      });
    }
    default:
      return state;
  }
};

export default issueReducer;
