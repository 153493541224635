import React, { FunctionComponent } from "react";
import { Button, Card, Divider, Form, message } from "antd";

import { setTaskStatus } from "../../../../api/task";
import { taskStatusToText } from "../../../../utils/task";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import ReadonlyExpressTask from "../../../../components/readonly-express-task";

import * as FormLayout from "../../../../styles/form";
import { TaskStatus } from "../../../../models";
import { TaskComponentProps } from "../TaskComponentProps";
import TimelineComponent from "../../../../components/timeline";

const ExpressProcess: FunctionComponent<TaskComponentProps> = ({ ticket, task, refreshTaskFn, refreshTicketFn }) => {
  const completeTask = async () => {
    try {
      const res = await setTaskStatus(task.id, TaskStatus.ToBeRated);
      await refreshTaskFn(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      <Divider />
      <Form.Item label="任务状态" {...FormLayout.formItemLayout}>
        {taskStatusToText(task.status)}
      </Form.Item>
      <Divider />
      <ReadonlyExpressTask task={task} />
      <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
        <Button type="primary" onClick={completeTask}>
          确认发货
        </Button>
      </Form.Item>
      <Divider />
      <TimelineComponent ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default ExpressProcess;
