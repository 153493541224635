import { NameValuePair } from "./NameValuePair";

export enum TicketStatus {
  UnderReview = 2,
  Approved = 3,
  SolutionUnderReview = 4,
  Processing = 5,
  Finished = 6,
  ToBeRated = 7,
  Closed = 8,
  Rejected = 9,
}

export const TicketStatusDescriptions: NameValuePair = {
  [TicketStatus.UnderReview]: "待审批",
  [TicketStatus.Approved]: "审批通过",
  [TicketStatus.SolutionUnderReview]: "方案等待审批",
  [TicketStatus.Processing]: "维修中",
  [TicketStatus.Finished]: "维修完成已评价",
  [TicketStatus.ToBeRated]: "已完成待评价",
  [TicketStatus.Closed]: "已取消",
  [TicketStatus.Rejected]: "审批被拒",
};
