import React from "react";
import { Table } from "antd";
import { ColumnProps, PaginationConfig } from "antd/es/table";

export type FetchDataFunction<T> = (pageNo: number) => void | Promise<void>;

export interface FetchRecord<T> {
  data: T[];
  total: number;
  page: number;
  pageSize: number;
}

export interface ListCardProps<T> {
  columns: ColumnProps<T>[];
  record: FetchRecord<T>;
  loading: boolean;
  rowKey?: string;
  fetch: FetchDataFunction<T>;
}

function ListCard<T extends object = any>({ columns, loading, record, rowKey = "id", fetch }: ListCardProps<T>) {
  const handleTableChange = async (paginationConfig: PaginationConfig) => {
    await fetch(paginationConfig?.current || 1);
  };
  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={record.data}
      rowKey={rowKey}
      onChange={handleTableChange}
      pagination={record}
    />
  );
}

export default ListCard;
