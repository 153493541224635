import React, { FunctionComponent, useEffect, useState } from "react";
import { FormComponentProps } from "antd/es/form";
import { Button, Card, Form, Input, message, Select } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../styles/form";
import { OrgTypeOptions } from "../../../models/OrgTypeOptions";
import { Brand, Organization, OrgType } from "../../../models";
import { CustomerTypeOptions } from "../../../models/CustomerTypeOptions";
import { ServiceTypeOptions } from "../../../models/ServiceTypeOptions";
import { getBrandList } from "../../../api/brand";
import { useHistory, useLocation } from "react-router";
import { addOrg, updateOrg } from "../../../api/org";

const OrgForm: FunctionComponent<FormComponentProps> = ({ form }) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [brands, setBrands] = useState<Brand[]>([]);
  const history = useHistory();
  const { state } = useLocation<{ organization: Organization }>();
  const { organization } = state || {};
  const loadBrands = async () => {
    setSelectLoading(true);
    let brands = await getBrandList();
    setBrands(brands);
    setSelectLoading(false);
  };
  useEffect(() => {
    if (!brands || brands.length === 0) {
      loadBrands().then(r => r);
    }
  }, [brands]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields(async (err, values: Organization) => {
      setLoading(true);
      try {
        if (err) {
          return;
        }
        console.log(values);
        let newOrg: Organization = Object.assign({}, organization, values);
        let result: Organization;
        if (organization) {
          result = await updateOrg(newOrg);
        } else {
          result = await addOrg(newOrg);
        }
        history.goBack();
      } catch (e) {
        message.error(e.message);
      } finally {
        setLoading(false);
      }
    });
  };
  const handleCancel = (e: React.FormEvent) => {
    history.goBack();
  };
  return (
    <>
      <Card title={"创建机构"}>
        <Form {...formItemLayout} onSubmit={handleSubmit} onReset={handleCancel}>
          <Form.Item label="机构类型">
            {getFieldDecorator("type", { initialValue: organization?.type || OrgType.CUSTOMER })(
              <Select>
                {OrgTypeOptions.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="机构名称">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "请输入机构名称！" }],
              initialValue: organization?.name,
            })(<Input placeholder="机构名称" />)}
          </Form.Item>
          <Form.Item label="机构描述">
            {getFieldDecorator("description", {
              rules: [{ required: true, message: "请输入机构描述！" }],
              initialValue: organization?.description,
            })(<Input placeholder="机构描述" />)}
          </Form.Item>
          <Form.Item label="机构联系人">
            {getFieldDecorator("contact", {
              rules: [{ required: true, message: "请输入机构联系人！" }],
              initialValue: organization?.contact,
            })(<Input placeholder="机构联系人" />)}
          </Form.Item>
          <Form.Item label="机构地址">
            {getFieldDecorator("address", {
              rules: [{ required: true, message: "请输入机构地址！" }],
              initialValue: organization?.address,
            })(<Input placeholder="机构地址" />)}
          </Form.Item>
          <Form.Item label="品牌">
            {getFieldDecorator("brand", { initialValue: organization?.brand })(
              <Select loading={selectLoading}>
                {brands.map(brand => (
                  <Select.Option key={brand.id} value={brand.id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="客户类型">
            {getFieldDecorator("customerType", { initialValue: organization?.customerType })(
              <Select>
                {CustomerTypeOptions.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="服务类型">
            {getFieldDecorator("service", { initialValue: organization?.service })(
              <Select>
                {ServiceTypeOptions.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              保存
            </Button>
            <Button style={{ marginLeft: 8 }} htmlType="reset">
              取消
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
export default Form.create({
  name: "OrgForm",
  mapPropsToFields(props) {},
})(OrgForm);
