import React, { FunctionComponent } from "react";
import { Card, Divider, message } from "antd";
import { addTaskSolution } from "../../../../api/task";
import { filesToAttachments } from "../../../../utils/form";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import RepairForm, { RepairFormProps } from "./repair-form";
import { TaskDetail, TaskDetailTypes } from "../../../../models";
import { TaskComponentProps } from "../TaskComponentProps";
import TimelineComponent from "../../../../components/timeline";

const RepairSolution: FunctionComponent<TaskComponentProps> = ({ ticket, task, refreshTaskFn, refreshTicketFn }) => {
  let repairFormRef: any;
  const saveRepairFormRef = (formRef: any) => {
    repairFormRef = formRef;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { form } = repairFormRef.props;
    form.validateFields(async (err: any, values: RepairFormProps) => {
      if (err) {
        return;
      }
      // merge material info
      const { materialKeys, materialName, materialNumber, materialUnit, materialPrice } = values;
      const materialInfo = materialKeys.map<TaskDetail>(k => ({
        name: materialName[k],
        num: materialNumber[k],
        unit: materialUnit[k],
        price: Math.round(materialPrice[k] * 100),
        type: TaskDetailTypes.MATERIALS,
      }));

      // merge repair info
      const { repairKeys, repairSolution, repairTime, repairUnit, repairPrice } = values;
      const repairInfo = repairKeys.map<TaskDetail>(k => ({
        name: repairSolution[k],
        num: repairTime[k],
        unit: repairUnit[k],
        price: Math.round(repairPrice[k] * 100),
        type: TaskDetailTypes.REPAIRS,
      }));

      // merge other info
      const { otherKeys, otherName, otherNumber, otherPrice } = values;
      const otherInfo = otherKeys.map<TaskDetail>(k => ({
        name: otherName[k],
        num: otherNumber[k],
        price: Math.round(otherPrice[k] * 100),
        type: TaskDetailTypes.OTHERS,
      }));

      try {
        const payload = {
          repairDetails: [...materialInfo, ...repairInfo, ...otherInfo],
          attachments: filesToAttachments(values.attachments),
          comment: values.comment,
        };

        const res = await addTaskSolution(task.id, payload);
        // const res = await setTaskStatus(task.id, TaskStatus.ToBeRated);
        await refreshTaskFn(res);
      } catch (err) {
        message.error(err.message);
      }
    });
  };

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      <Divider />
      <RepairForm wrappedComponentRef={saveRepairFormRef} onSubmit={handleSubmit} />
      <Divider />
      <TimelineComponent ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default RepairSolution;
