import React from "react";
import { css } from "@emotion/core";
import { Button, Col, Form, Icon, Input, InputNumber, Row, Upload } from "antd";
import { FormComponentProps } from "antd/es/form";

import { normalizeFile } from "../../../../utils/form";
import { checkIsImageBeforeUpload } from "../../../../utils/attachment";
import { getEndpoint } from "../../../../config";

import * as FormLayout from "../../../../styles/form";

export interface RepairFormProps extends FormComponentProps {
  materialKeys: number[];
  materialName: string[];
  materialNumber: number[];
  materialUnit: string[];
  materialPrice: number[];
  repairKeys: number[];
  repairSolution: string[];
  repairTime: number[];
  repairUnit: string[];
  repairPrice: number[];
  otherKeys: number[];
  otherName: string[];
  otherNumber: number[];
  otherPrice: number[];
  comment?: string;
  attachments: any[];
}

interface OwnProps {
  onSubmit: (e: React.FormEvent) => void;
}

type AllProps = RepairFormProps & OwnProps;

interface State {
  readonly materialId: number;
  readonly repairId: number;
  readonly otherId: number;
}

class RepairForm extends React.Component<AllProps, State> {
  state = {
    materialId: 1,
    repairId: 1,
    otherId: 1,
  };

  static defaultProps = {
    materialKeys: [],
    materialName: [],
    materialNumber: [],
    materialUnit: [],
    materialPrice: [],
    repairKeys: [],
    repairSolution: [],
    repairTime: [],
    repairUnit: [],
    repairPrice: [],
    otherKeys: [],
    otherName: [],
    otherNumber: [],
    otherPrice: [],
    attachments: [],
    onSubmit: (e: React.FormEvent) => {},
  };

  removeMaterial = (k: number) => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("materialKeys");
    if (keys.length === 0) {
      return;
    }

    setFieldsValue({
      materialKeys: keys.filter((key: number) => key !== k),
    });
  };

  addMaterial = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("materialKeys");
    const nextKeys = [...keys, this.state.materialId];
    this.setState({
      materialId: this.state.materialId + 1,
    });

    setFieldsValue({
      materialKeys: nextKeys,
    });
  };

  removeRepair = (k: number) => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("repairKeys");
    if (keys.length === 0) {
      return;
    }

    setFieldsValue({
      repairKeys: keys.filter((key: number) => key !== k),
    });
  };

  addRepair = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("repairKeys");
    const nextKeys = [...keys, this.state.repairId];
    this.setState({
      repairId: this.state.repairId + 1,
    });

    setFieldsValue({
      repairKeys: nextKeys,
    });
  };

  removeOther = (k: number) => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("otherKeys");
    if (keys.length === 0) {
      return;
    }

    setFieldsValue({
      otherKeys: keys.filter((key: number) => key !== k),
    });
  };

  addOther = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const keys = getFieldValue("otherKeys");
    const nextKeys = [...keys, this.state.otherId];
    this.setState({
      otherId: this.state.otherId + 1,
    });

    setFieldsValue({
      otherKeys: nextKeys,
    });
  };

  render() {
    const { onSubmit, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    getFieldDecorator("materialKeys", { initialValue: [] });
    const materialKeys = getFieldValue("materialKeys");
    const materialItems = materialKeys.map((k: number, index: number) => (
      <Form.Item
        key={k}
        label={
          <span>
            <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.removeMaterial(k)} /> 材料
            {index + 1}
          </span>
        }
        required={false}
        help={null}
        hasFeedback={false}
        style={{ marginBottom: 0 }}
        {...FormLayout.dynamicFormItemLayout}>
        <Row>
          <Col span={10}>
            <Form.Item>
              {getFieldDecorator(`materialName[${k}]`, {
                rules: [{ required: true, message: "请输入品名" }],
              })(<Input placeholder="品名" />)}
            </Form.Item>
          </Col>

          <Col span={6} offset={2}>
            <Form.Item label="数量" css={InlineFormItem}>
              {getFieldDecorator(`materialNumber[${k}]`, {
                initialValue: 1,
                rules: [{ required: true, message: "请输入数量" }],
              })(<InputNumber min={1} />)}
            </Form.Item>
          </Col>

          {getFieldDecorator(`materialUnit[${k}]`, {
            initialValue: "",
          })(<Input hidden={true} />)}

          <Col span={6}>
            <Form.Item label="单价" css={InlineFormItem}>
              {getFieldDecorator(`materialPrice[${k}]`, {
                rules: [{ required: true, message: "请输入单价" }],
              })(<InputNumber placeholder="单价" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    ));

    getFieldDecorator("repairKeys", { initialValue: [] });
    const repairKeys = getFieldValue("repairKeys");
    const repairItems = repairKeys.map((k: number, index: number) => (
      <div key={k}>
        <Form.Item
          label={
            <span>
              <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.removeRepair(k)} />{" "}
              维修方式{index + 1}
            </span>
          }
          required={false}
          key={k}>
          {getFieldDecorator(`repairSolution[${k}]`, {
            rules: [{ required: true, message: "请输入维修方式" }],
          })(<Input placeholder="维修方式" />)}
        </Form.Item>

        <Form.Item label="维修工时">
          {getFieldDecorator(`repairTime[${k}]`, {
            initialValue: 1,
            rules: [{ required: true, message: "请输入维修工时" }],
          })(<InputNumber min={1} />)}
        </Form.Item>

        {getFieldDecorator(`repairUnit[${k}]`, {
          initialValue: "",
        })(<Input hidden={true} />)}

        <Form.Item label="单价">
          {getFieldDecorator(`repairPrice[${k}]`, {
            rules: [{ required: true, message: "请输入单价" }],
          })(<InputNumber placeholder="单价" />)}
        </Form.Item>
      </div>
    ));

    getFieldDecorator("otherKeys", { initialValue: [] });
    const otherKeys = getFieldValue("otherKeys");
    const otherItems = otherKeys.map((k: number, index: number) => (
      <div key={k}>
        <Form.Item
          label={
            <span>
              <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => this.removeRepair(k)} />{" "}
              其他费用{index + 1}
            </span>
          }
          required={false}
          key={k}>
          {getFieldDecorator(`otherName[${k}]`, {
            rules: [{ required: true, message: "请输入费用名字" }],
          })(<Input placeholder="其他费用" />)}
        </Form.Item>

        <Form.Item label="数量">
          {getFieldDecorator(`otherNumber[${k}]`, {
            initialValue: 1,
            rules: [{ required: true, message: "请输入数量" }],
          })(<InputNumber min={1} />)}
        </Form.Item>

        <Form.Item label="单价">
          {getFieldDecorator(`otherPrice[${k}]`, {
            rules: [{ required: true, message: "请输入单价" }],
          })(<InputNumber placeholder="单价" />)}
        </Form.Item>
      </div>
    ));

    return (
      <Form {...FormLayout.formItemLayout} onSubmit={onSubmit}>
        {materialItems}
        <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
          <Button type="dashed" onClick={this.addMaterial}>
            <Icon type="plus" /> 添加材料
          </Button>
        </Form.Item>

        {repairItems}
        <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
          <Button type="dashed" onClick={this.addRepair}>
            <Icon type="plus" /> 添加维修方案
          </Button>
        </Form.Item>

        {otherItems}
        <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
          <Button type="dashed" onClick={this.addOther}>
            <Icon type="plus" /> 添加其他费用
          </Button>
        </Form.Item>

        <Form.Item label="备注">
          {getFieldDecorator("comment")(<Input.TextArea placeholder="请输入备注" autoSize={{ minRows: 5 }} />)}
        </Form.Item>

        <Form.Item label="其他附件">
          {getFieldDecorator("attachments", {
            valuePropName: "fileList",
            getValueFromEvent: normalizeFile,
          })(
            <Upload.Dragger
              name="attachment"
              action={`${getEndpoint()}/attachment`}
              accept="image/*"
              headers={{
                Authorization: sessionStorage.getItem("tk") || "",
              }}
              beforeUpload={checkIsImageBeforeUpload}
              multiple>
              <Icon type="upload" /> 附件1维修前，附件2完工后，附件3店方确认表
            </Upload.Dragger>,
          )}
        </Form.Item>

        <Form.Item {...FormLayout.formItemLayoutWithOutLabel}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedRepairForm = Form.create<RepairFormProps>({ name: "repair_form" })(RepairForm);

export default WrappedRepairForm;

const InlineFormItem = css`
  display: flex;
`;
