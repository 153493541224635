import { Route } from "react-router";
import React from "react";
import ChangePassword from "./my/userinfo/ChangePassword";
import ListView from "./list-view";
import TicketDetail from "./customer/ticket-detail";

export function getRouters(prefix: string) {
  return (
    <>
      <Route path={`${prefix}/my/userinfo/changePassword`} component={ChangePassword} />
      <Route path={`${prefix}/ticket/:ticketId(\\d+)`} component={TicketDetail} />
      <Route exact strict path={`${prefix}/list-view/:mode`} component={ListView} />
    </>
  );
}
