import React, { FunctionComponent, useState } from "react";
import { Button, Card, Form, Input, message } from "antd";
import { FormComponentProps } from "antd/es/form";
import { formItemLayout, tailFormItemLayout } from "../../../styles/form";
import { changePwd } from "../../../api/user";

interface ChangePassword {
  originPwd: string;
  newPwd: string;
  confirmNewPwd: string;
}

const ChangePassword: FunctionComponent<FormComponentProps<ChangePassword>> = ({ form }) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields(async (err, values: ChangePassword) => {
      if (err) {
        console.error(err);
        return;
      }
      setLoading(true);
      try {
        let result = await changePwd({ ...values });
        console.log(result);
        message.success("密码修改成功！");
        form.resetFields();
      } catch (e) {
        console.error(e);
        message.error(e.message);
      } finally {
        setLoading(false);
      }
    });
  };
  return (
    <Card title={"修改密码"}>
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="原始密码">
          {getFieldDecorator("originPwd", {
            rules: [{ required: true, message: "请输入原始密码" }],
          })(<Input.Password placeholder="原始密码" autoComplete={"off"} />)}
        </Form.Item>
        <Form.Item label="新的密码" hasFeedback>
          {getFieldDecorator("newPwd", {
            rules: [{ required: true, message: "请输入新的密码" }],
          })(<Input.Password placeholder="新的密码" autoComplete={"off"} />)}
        </Form.Item>
        <Form.Item label="再次输入" hasFeedback>
          {getFieldDecorator("confirmNewPwd", {
            rules: [
              { required: true, message: "请再次输入新的密码" },
              {
                validator: (rule, value, callback, source, options) => {
                  console.log(rule, value, source, options);
                  if (value !== form.getFieldValue("newPwd")) {
                    callback("两次输入的新密码不一致，请重新输入");
                    return;
                  }
                  callback();
                },
              },
            ],
          })(<Input.Password placeholder="再次输入新的密码" autoComplete={"off"} />)}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            修改
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Form.create({ name: "ChangePasswordForm" })(ChangePassword);
