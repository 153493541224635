import { Ticket, TicketStatusDescriptions } from "../../models";
import { getMyTickets } from "../../api/ticket";
import * as GlobalStyle from "../../styles/global";
import { css } from "@emotion/core";
import { Button, Icon, Radio } from "antd";
import { getValidTicketStatus } from "../../utils/ticket";
import { Link } from "react-router-dom";
import React from "react";
import { ListViewConfig } from "./list-view-config";
import { formatTicket } from "./formatters";
import { DefaultTicketColumnConfig } from "./DefaultColumnConfig";

export const CustomerStaffListViewConfig: ListViewConfig<Ticket>[] = [
  {
    key: "basic-list",
    columns: DefaultTicketColumnConfig,
    title: "我的任务",
    rowKey: "id",
    fetch: async (pageNo, pageSize, params) => formatTicket(await getMyTickets({ page: pageNo, ...params })),
    navBar: props => (
      <div
        css={[
          GlobalStyle.BeforeTable,
          css`
            display: flex;
          `,
        ]}>
        <div
          css={css`
            flex: 1;
          `}>
          <Radio.Group
            onChange={async ({ target }) => {
              if (target.value === "all") {
                await props.filterChange({ status: undefined });
              } else {
                await props.filterChange({ status: target.value });
              }
            }}
            defaultValue="all"
            buttonStyle="solid">
            <Radio.Button value="all">全部</Radio.Button>
            {getValidTicketStatus().map(status => (
              <Radio.Button key={status} value={status}>
                {TicketStatusDescriptions[status]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <Button className="new-ticket">
          <Link to={`/dashboard/customer/ticket/new`}>
            <Icon type="plus" />
            录入保修单
          </Link>
        </Button>
      </div>
    ),
  },
];
