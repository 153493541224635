import { Ticket } from "../../models";
//
// export enum TicketStatus {
//   UnderReview = 2,
//   Approved = 3,
//   SolutionUnderReview = 4,
//   Processing = 5,
//   Finished = 6,
//   ToBeRated = 7,
//   Closed = 8,
//   Rejected = 9,
// }
//
// export interface Claim {
//   id: number;
//   group: string;
//   issue: string;
//   count: number;
//   photos: Attachment[];
// }
//
//
// export interface Attachment {
//   attachmentId: string;
//   attachmentName: string;
// }
//
// export interface RawTicket {
//   createdBy: number;
//   orgId: number;
//   orgSerial: string;
//   orgName: string;
//   orgRegion: string;
//   orgAddress: string;
//   contact: string;
//   phone: string;
//   urgent: boolean;
//   expectedDate?: string;
//   issues: Claim[];
//   extraIssue: string;
//   extraPhotos: Attachment[];
//   comment: string;
// }
//
// export interface History {
//   action: string;
//   timestamp: number;
//   userId: number;
//   username: string;
// }
//
// export interface Ticket extends Omit<RawTicket, "comment"> {
//   id: number;
//   creator: UserInfo;
//   ticketNumber: string;
//   createTime: number;
//   status: TicketStatus;
//   timeline: History[];
//   tasks: number[];
// }

export interface TicketRecord {
  [id: number]: Ticket;
}

export enum TicketActionTypes {
  ADD = "TICKET/ADD",
  ADD_RANGE = "TICKET/ADD_RANGE",
  SET_TIMELINE = "TICKET/SET_TIMELINE",
}

export interface TicketState {
  readonly allIds: number[];
  readonly byId: TicketRecord;
}
