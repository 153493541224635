import { ServiceType, UserLevel } from "../../models";
import { MenuConfig } from "../MenuConfig";

const RepairAdminNavigators = (prefix: string): MenuConfig[] => [
  {
    name: "首页",
    url: `${prefix}/list-view/org-repair-list`,
    icon: "home",
    isRouter: false,
  },
  {
    name: "搜索",
    url: `${prefix}/list-view/repair-task-query`,
    icon: "search",
    isRouter: false,
  },
  {
    name: "报表分析",
    url: `${prefix}/customer/report`,
    icon: "bar-chart",
    isRouter: true,
    component: () => import("../customer/report"),
  },
  {
    name: "我的信息",
    url: `${prefix}/my/userinfo/`,
    icon: "user",
    isRouter: true,
    component: () => import("../my/userinfo/UserInfo"),
  },
];

const RepairStaffNavigators = (prefix: string): MenuConfig[] => [
  {
    name: "首页",
    url: `${prefix}/list-view/my-repair-list`,
    icon: "home",
    isRouter: false,
  },
  {
    name: "我的信息",
    url: `${prefix}/my/userinfo/`,
    icon: "user",
    isRouter: true,
    component: () => import("../my/userinfo/UserInfo"),
  },
];
const ExpressAdminNavigators = (prefix: string): MenuConfig[] => [
  {
    name: "首页",
    url: `${prefix}/list-view/express-list`,
    icon: "home",
    isRouter: false,
  },
  {
    name: "搜索",
    url: `${prefix}/list-view/express-task-query`,
    icon: "search",
    isRouter: false,
  },
  {
    name: "报表分析",
    url: `${prefix}/customer/report`,
    icon: "bar-chart",
    isRouter: true,
    component: () => import("../customer/report"),
  },
  {
    name: "我的信息",
    url: `${prefix}/my/userinfo/`,
    icon: "user",
    isRouter: true,
    component: () => import("../my/userinfo/UserInfo"),
  },
];

const RepairNavigators = {
  [`${UserLevel.ADMIN}`]: RepairAdminNavigators,
  [`${UserLevel.STAFF}`]: RepairStaffNavigators,
};
const ExpressNavigators = {
  [`${UserLevel.ADMIN}`]: ExpressAdminNavigators,
};
const Navigators = {
  [`${ServiceType.REPAIR}`]: RepairNavigators,
  [`${ServiceType.EXPRESS}`]: ExpressNavigators,
};

export function findNavigator(serviceType: number, level: number, prefix: string): MenuConfig[] {
  const nav = Navigators[`${serviceType}`];
  if (!nav) {
    return [];
  }
  const subNav = nav[`${level}`];
  if (!subNav) {
    return [];
  }
  return subNav(prefix);
}
