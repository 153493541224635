import { Task, TaskWithTicket, Ticket } from "../../models";
import { FetchRecord } from "../../components/list-card";
import { groupBy } from "lodash";

export function formatTask(res: {
  tickets: Ticket[];
  tasks: Task[];
  total: number;
  page: number;
  pageSize: number;
}): FetchRecord<TaskWithTicket> {
  const ticketsGroupById = groupBy<Ticket>(res.tickets || [], res => res.id);
  return {
    data: (res.tasks || []).map<TaskWithTicket>(task => ({
      ...task,
      ticket: ticketsGroupById[task.ticketId][0],
    })),
    total: res.total,
    page: res.page,
    pageSize: res.pageSize,
  };
}

export function formatTicket(res: {
  array: Ticket[];
  total: number;
  page: number;
  pageSize: number;
}): FetchRecord<Ticket> {
  return {
    total: res.total,
    page: res.page,
    pageSize: res.pageSize,
    data: res.array,
  };
}
