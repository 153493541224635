import React from "react";
import QueueAnim from "rc-queue-anim";
import { Col, Row } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { getChildrenToRender } from "./utils";

class Content1 extends React.PureComponent {
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { wrapper, titleWrapper, page, OverPack: overPackData, childWrapper } = dataSource;
    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>{titleWrapper.children.map(getChildrenToRender)}</div>
          <OverPack {...overPackData}>
            <QueueAnim type="bottom" key="block" leaveReverse component={Row} componentProps={childWrapper}>
              {childWrapper.children.map((block, i) => {
                const { children: item, ...blockProps } = block;
                return (
                  <Row
                    key={i.toString()}
                    {...blockProps}
                    type={"flex"}
                    justify={"space-around"}
                    align={"middle"}
                    md={8}
                    xs={24}>
                    <Col span={12}>
                      <div {...item}>{item.children.left.children.map(getChildrenToRender)}</div>
                    </Col>
                    <Col span={12}>
                      <div {...item}>{item.children.right.children.map(getChildrenToRender)}</div>
                    </Col>
                  </Row>
                );
              })}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default Content1;
