import { NameValuePair } from "./NameValuePair";

export enum UserLevel {
  ADMIN = 1,
  STAFF = 2,
  OTHER = 3, //预留的
}

export const UserLevelDescriptions: NameValuePair = {
  [UserLevel.ADMIN]: "一级",
  [UserLevel.STAFF]: "二级",
  [UserLevel.OTHER]: "三级",
};
