import React from "react";
import { connect } from "react-redux";
import { Avatar, Button, Comment, Form, Input, List, message, Tooltip } from "antd";
import moment from "moment";

import { ApplicationState } from "../../store";
import { setTimeline } from "../../store/ticket/actions";
import { Auth } from "../../store/auth/types";
import { addComment } from "../../api/ticket";
import { Ticket, Timeline } from "../../models";

interface OwnProps {
  ticket: Ticket;
  refreshTicketFn: (ticket?: Ticket) => void | Promise<void>;
}

interface PropsFromState {
  auth?: Auth;
}

interface PropsFromDispatch {
  setTimeline: typeof setTimeline;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch;

interface State {
  readonly submitting: boolean;
  readonly comment: string;
}

class TimelineComponent extends React.Component<AllProps, State> {
  state = {
    submitting: false,
    comment: "",
  };

  submitComment = async () => {
    const { ticket, setTimeline, refreshTicketFn } = this.props;

    const comment = this.state.comment.trim();
    if (comment === "") {
      return;
    }

    this.setState({ submitting: false });

    try {
      const timeline: Timeline[] = await addComment(ticket.id, comment);
      setTimeline(ticket.id, timeline);
      ticket.timeline = timeline;
      await refreshTicketFn(ticket);
      this.setState({ comment: "" });
    } catch (err) {
      message.error(err.message);
    }

    this.setState({ submitting: false });
  };

  inputComment = (e: any) => {
    this.setState({ comment: e.target.value });
  };

  render() {
    const { ticket } = this.props;

    return (
      <div>
        <List
          header={`时间线（${ticket.timeline.length}条记录）`}
          itemLayout="horizontal"
          dataSource={ticket.timeline}
          renderItem={(opHistory: Timeline) => (
            <li>
              <Comment
                author={opHistory.userName}
                avatar={<Avatar icon="user" size="large" />}
                content={opHistory.action}
                datetime={
                  <Tooltip title={moment(opHistory.timestamp).format("YYYY-MM-DD HH:mm:ss")}>
                    <span>{moment(opHistory.timestamp).fromNow()}</span>
                  </Tooltip>
                }
              />
            </li>
          )}
        />

        <Comment
          avatar={<Avatar icon="user" size="large" />}
          content={
            <div>
              <Form.Item>
                <Input.TextArea rows={4} onChange={this.inputComment} value={this.state.comment} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={this.state.submitting} onClick={this.submitComment} type="primary">
                  添加评论
                </Button>
              </Form.Item>
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  auth: state.auth.data,
});

const mapDispatchToProps: PropsFromDispatch = {
  setTimeline,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimelineComponent);
