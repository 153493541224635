import { NameValuePair } from "./NameValuePair";

export enum ServiceType {
  NOTHING = 0,
  REPAIR = 1,
  EXPRESS = 2,
}

export const ServiceTypeDescriptions: NameValuePair = {
  [`${ServiceType.NOTHING.valueOf()}`]: "无",
  [`${ServiceType.REPAIR.valueOf()}`]: "维修安装",
  [`${ServiceType.EXPRESS.valueOf()}`]: "物料替换",
};
