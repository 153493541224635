import { OrgType, OrgTypeDescriptions, SelectOption } from "./index";

export const OrgTypeOptions: SelectOption<number>[] = [
  {
    label: OrgTypeDescriptions[OrgType.CUSTOMER],
    value: OrgType.CUSTOMER,
  },
  {
    label: OrgTypeDescriptions[OrgType.VENDOR],
    value: OrgType.VENDOR,
  },
  {
    label: OrgTypeDescriptions[OrgType.PLATFORM],
    value: OrgType.PLATFORM,
  },
];
