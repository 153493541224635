import { SelectOption, ServiceType, ServiceTypeDescriptions } from "./index";

export const AvailableServiceTypeOptions: SelectOption<number>[] = [
  {
    label: ServiceTypeDescriptions[ServiceType.REPAIR],
    value: ServiceType.REPAIR,
  },
  {
    label: ServiceTypeDescriptions[ServiceType.EXPRESS],
    value: ServiceType.EXPRESS,
  },
];

export const ServiceTypeOptions: SelectOption<number>[] = [
  {
    label: ServiceTypeDescriptions[ServiceType.NOTHING],
    value: ServiceType.NOTHING,
  },
  ...AvailableServiceTypeOptions,
];
