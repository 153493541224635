import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { UserInfo } from "../../store/userinfo/types";
import { findListViewConfig, ListViewConfig } from "./list-view-config";
import styled from "@emotion/styled";
import ListCard, { FetchRecord } from "../../components/list-card";
import { useParams } from "react-router";
import NotFound from "../../components/not-found";
import { Card, message } from "antd";

const MAX_PAGE_SIZE = 10;

export interface ListViewProps {
  mode?: string;
}

const ListView: FunctionComponent<ListViewProps> = ({ mode: modeProps }) => {
  const userInfo = useSelector<ApplicationState, UserInfo | undefined>(state => state.userInfo.data);
  const { mode } = useParams();

  if (!userInfo) {
    return null;
  }
  const listViewConfig = findListViewConfig(mode || modeProps || "");
  if (listViewConfig.length === 0) {
    return <NotFound />;
  }
  return (
    <Container>
      {listViewConfig.map((config, index) => (
        <ListViewWrapper key={index} {...config} />
      ))}
    </Container>
  );
};
export default ListView;

const ListViewWrapper: FunctionComponent<ListViewConfig<any>> = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [record, setRecord] = useState<FetchRecord<any>>({
    page: 1,
    data: [],
    pageSize: 0,
    total: 0,
  });
  const [params, setParams] = useState({});
  useEffect(() => {
    //首次加载视图
    handleFetch(1).then(r => r);
  }, [params]);
  const handleFetch = async (pageNo: number) => {
    setLoading(true);
    try {
      let result = await props.fetch(pageNo, MAX_PAGE_SIZE, params);
      setRecord(result);
    } catch (e) {
      message.error(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title={`${props.title} (${record.total}条)`}>
      {props.navBar &&
        props.navBar({
          filterChange: async changedParams => {
            console.log(changedParams);
            setParams(changedParams);
          },
        })}
      <ListCard columns={props.columns} loading={loading} rowKey={props.rowKey} record={record} fetch={handleFetch} />
    </Card>
  );
};
const Container = styled.div`
  .ant-card {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
