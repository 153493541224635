import React from "react";
import { ServiceType, TaskStatus, UserLevel } from "../../../models";
import Default from "./default";
import { TaskComponentProps } from "./TaskComponentProps";
import ExpressSolution from "./express/express-solution";
import WithExpress from "./with-express";
import ExpressProcess from "./express/process";
import NeedAssign from "./admin/need-assign";
import Assignment from "./operator/assignment";
import RepairSolution from "./operator/repair-solution";
import WithRepair from "./with-repair";
import RepairProcess from "./operator/process";

interface ViewConfig {
  [status: number]: (componentProps: TaskComponentProps) => JSX.Element;
}

/**
 * 操作员视图配置
 */
const RepairOperatorViewConfig: ViewConfig = {
  [TaskStatus.ToBeAccepted]: (componentProps: TaskComponentProps) => <Assignment {...componentProps} />,
  [TaskStatus.Accepted]: (componentProps: TaskComponentProps) => <RepairSolution {...componentProps} />,
  [TaskStatus.SolutionUnderReview]: (componentProps: TaskComponentProps) => <WithRepair {...componentProps} />,
  [TaskStatus.Closed]: (componentProps: TaskComponentProps) => <WithRepair {...componentProps} />,
  [TaskStatus.ToBeRated]: (componentProps: TaskComponentProps) => <WithRepair {...componentProps} />,
  [TaskStatus.Finished]: (componentProps: TaskComponentProps) => <WithRepair {...componentProps} />,
  [TaskStatus.Processing]: (componentProps: TaskComponentProps) => <RepairProcess {...componentProps} />,
};
/**
 * 管理员视图配置
 */
const RepairAdminViewConfig: ViewConfig = {
  [TaskStatus.Open]: (componentProps: TaskComponentProps) => <NeedAssign {...componentProps} />,
  [TaskStatus.Rejected]: (componentProps: TaskComponentProps) => <NeedAssign {...componentProps} />,
};
/**
 * 快递管理员界面
 */
const ExpressAdminViewConfig: ViewConfig = {
  [TaskStatus.Open]: (componentProps: TaskComponentProps) => <ExpressSolution {...componentProps} />,
  [TaskStatus.SolutionUnderReview]: (componentProps: TaskComponentProps) => <WithExpress {...componentProps} />,
  [TaskStatus.Closed]: (componentProps: TaskComponentProps) => <WithExpress {...componentProps} />,
  [TaskStatus.ToBeRated]: (componentProps: TaskComponentProps) => <WithExpress {...componentProps} />,
  [TaskStatus.Finished]: (componentProps: TaskComponentProps) => <WithExpress {...componentProps} />,
  [TaskStatus.Processing]: (componentProps: TaskComponentProps) => <ExpressProcess {...componentProps} />,
};
const DefaultViewConfig = (componentProps: TaskComponentProps) => <Default {...componentProps} />;

export function findView(serviceType: ServiceType, level: UserLevel, componentProps: TaskComponentProps) {
  if (!componentProps.ticket) {
    return null;
  }
  let viewConfig = null;
  if (serviceType === ServiceType.EXPRESS) {
    viewConfig = ExpressAdminViewConfig;
  } else if (serviceType === ServiceType.REPAIR) {
    if (level === UserLevel.STAFF) {
      viewConfig = RepairOperatorViewConfig;
    } else if (level === UserLevel.ADMIN) {
      viewConfig = RepairAdminViewConfig;
    } else {
      return null;
    }
  } else {
    return null;
  }
  let fn = viewConfig[componentProps.task?.status || 0] || DefaultViewConfig;
  return fn(componentProps);
}
