import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { UserInfo, UserInfoActionTypes } from "./types";
import { fetchError, fetchRequest, fetchSuccess } from "./actions";
import { getUserInfo } from "../../api/user";

function* handleFetchUserInfoRequest(action: ReturnType<typeof fetchRequest>) {
  try {
    const res = yield call(getUserInfo);
    const userInfo: UserInfo = {
      id: res.id,
      userName: res.userName,
      description: res.description,
      firstName: res.firstName,
      lastName: res.lastName,
      level: res.level,
      orgType: res.orgType,
      organization: {
        id: res.organization.id,
        customerType: res.organization.customerType,
        name: res.organization.name,
        description: res.organization.description,
        service: res.organization.service,
      },
    };
    yield put(fetchSuccess(userInfo));
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* watchFetchUserInfoRequest() {
  yield takeEvery(UserInfoActionTypes.FETCH_REQUEST, handleFetchUserInfoRequest);
}

function* userInfoSaga() {
  yield all([fork(watchFetchUserInfoRequest)]);
}

export default userInfoSaga;
