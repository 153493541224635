import { GET, POST, PUT } from "../utils/api";
import { Attachment, Express, ServiceType, TaskDetail, TaskStatus } from "../models";

export async function getTask(taskId: number) {
  return GET({
    path: `/task/${taskId}`,
  });
}

export interface MyOrgTasksReq {
  page: number;
  taskType: ServiceType;
  from?: string;
  until?: string;
  keyword?: string;
  assigned?: number;
}

export async function getTasksInOrg(params: MyOrgTasksReq) {
  return GET({
    path: `/myOrgTasks`,
    params,
  });
}

export async function getMyTasks(pageNo: number) {
  return GET({
    path: `/myTasks`,
    params: {
      page: pageNo,
    },
  });
}

export async function assignTask(taskId: number, userId: number, assignDesc?: string, serviceTime?: string) {
  return POST({
    path: `/task/${taskId}/assign`,
    body: {
      userId,
      assignDesc,
      serviceTime,
    },
  });
}

export interface TaskSolutionReq {
  expressList?: Express[];
  repairDetails: TaskDetail[];
  attachments: Attachment[];
  comment?: string;
}

export async function addTaskSolution(taskId: number, payload: TaskSolutionReq) {
  return POST({
    path: `/task/${taskId}`,
    body: payload,
  });
}

export async function setTaskStatus(taskId: number, status: TaskStatus) {
  return PUT({
    path: `/task/${taskId}`,
    body: {
      status,
    },
  });
}

export async function rateTask(taskId: number, score: number) {
  return POST({
    path: `/task/${taskId}/rate`,
    body: {
      rating: score,
    },
  });
}

export async function getOrgServeTicketReport(from: string, to: string) {
  return GET({ path: `/MyOrgServeTicketReport`, params: { from, to } });
}
