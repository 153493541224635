import React, { FunctionComponent } from "react";
import { Card, Divider, Form } from "antd";

import { taskStatusToText } from "../../../utils/task";
import { formItemLayout } from "../../../styles/form";

import ReadonlyTicket from "../../../components/readonly-ticket";
import ReadonlyRepairTask from "../../../components/readonly-repair-task";
import { TaskComponentProps } from "./TaskComponentProps";
import TimelineComponent from "../../../components/timeline";

const WithRepair: FunctionComponent<TaskComponentProps> = ({ task, ticket, refreshTicketFn }) => (
  <Card>
    <ReadonlyTicket ticket={ticket} />
    <Divider />
    <Form.Item label="任务状态" {...formItemLayout}>
      {taskStatusToText(task.status)}
    </Form.Item>
    <Divider />
    <ReadonlyRepairTask task={task} showPrice={true} />
    <Divider />
    <TimelineComponent ticket={ticket} refreshTicketFn={refreshTicketFn} />
  </Card>
);

export default WithRepair;
