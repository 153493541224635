import { IndexValuePair } from "./NameValuePair";

export enum CustomerType {
  /**
   * 直营店
   */
  DirectSaleStore = 1,
  /**
   * 渠道商
   */
  Channel = 2,
  /**
   * 总部
   */
  Headquarters = 3,
}

export const CustomerTypeDescriptions: IndexValuePair = {
  [CustomerType.DirectSaleStore]: "直营店",
  [CustomerType.Channel]: "渠道商",
  [CustomerType.Headquarters]: "总部",
};
