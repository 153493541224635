import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { Spin } from "antd";

const Loading: FunctionComponent = () => (
  <Container>
    <Spin size="large" tip="加载中，请耐心等待" />
  </Container>
);

export default Loading;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
