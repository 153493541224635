import React, { FunctionComponent, useCallback } from "react";
import styled from "@emotion/styled";
import { Icon, Layout, Menu } from "antd";
import { signOutRequest } from "../../store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationState } from "../../store";
import { RouterState } from "connected-react-router";
import logo from "../../assets/logo.svg";

interface MenuConfig {
  name: string;
  url: string;
  icon: string;
}

interface SidebarProps {
  menuConfigs?: MenuConfig[];
}

export const Sidebar: FunctionComponent<SidebarProps> = ({ menuConfigs = [] }) => {
  const dispatch = useDispatch();
  const signOut: any = useCallback(() => dispatch(signOutRequest()), [dispatch]);
  const {
    location: { pathname },
  } = useSelector<ApplicationState, RouterState>(state => state.router);
  return (
    <FixedSidebar>
      <SidebarContainer>
        <Logo>
          <LogoImg src={logo} alt="MAPS" />
        </Logo>
        <Menu theme="dark" mode="inline" selectedKeys={[pathname]} style={{ flex: 1 }}>
          {menuConfigs.map(i => (
            <Menu.Item key={i.url}>
              <Link to={i.url}>
                <Icon type={i.icon} />
                <span>{i.name}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
        {/* 通用菜单 BEGIN */}
        <Menu theme="dark" mode="inline" selectable={false}>
          <Menu.Item key="1">
            <a href="mailto:support@vigoroup.com">
              <Icon type="mail" />
              <span>反馈和意见</span>
            </a>
          </Menu.Item>
          <Menu.Item key="2" onClick={() => signOut()}>
            <Icon type="logout" />
            <span>登出</span>
          </Menu.Item>
          <Menu.Item>
            <Icon type="dingding" />
            <span>版本：{process.env.SYSTEM_VERSION}</span>
          </Menu.Item>
        </Menu>
        {/* 通用菜单 END */}
      </SidebarContainer>
    </FixedSidebar>
  );
};

const FixedSidebar = styled(Layout.Sider)`
  height: 100vh;
  overflow: auto;
  position: fixed;
  left: 0;
`;

const SidebarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Logo = styled.div`
  height: 64px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImg = styled.img`
  width: 100%;
  //margin-left: -10px;
  //margin-top: -8px;
`;
