export enum TaskDetailTypes {
  // 材料费用
  MATERIALS = 1,
  // 维修费用（俗称人工费用）
  REPAIRS = 2,
  // 快递材料费用
  EXPRESS_MATERIALS = 3,
  // 其他费用
  OTHERS = 4,
}
