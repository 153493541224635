import { Task } from "../../models";
//
// export enum TaskStatus {
//   Open = 1,
//   ToBeAccepted = 2,
//   Accepted = 3,
//   Rejected = 4,
//   SolutionUnderReview = 5,
//   Closed = 6,
//   Processing = 7,
//   ToBeRated = 8,
//   Finished = 9,
// }
//
// export enum TaskType {
//   REPAIR = 1,
//   EXPRESS = 2,
// }
//
// export interface MaterialItem {
//   name: string;
//   num: number;
//   unit: string;
//   price: number;
// }
//
// export interface RepairItem {
//   name: string;
//   num: number;
//   unit: string;
//   price: number;
// }
//
// export interface OtherItem {
//   name: string;
//   num: number;
//   price: number;
// }
//
// export interface ExpressItem {
//   company: string;
//   tracking: string;
// }
//
// export interface RawRepairTask {
//   type: TaskType.REPAIR;
//   materials: MaterialItem[];
//   labors: RepairItem[];
//   others: OtherItem[];
//   attachments: Attachment[];
//   comment: string;
// }
//
// export interface RawExpressTask {
//   type: TaskType.EXPRESS;
//   materials: MaterialItem[];
//   express: ExpressItem[];
//   attachments: Attachment[];
//   comment: string;
// }
//
// export interface RepairTask extends RawRepairTask {
//   id: number;
//   status: TaskStatus;
//   ticketId: number;
//   rating: number;
// }
//
// export interface ExpressTask extends RawExpressTask {
//   id: number;
//   status: TaskStatus;
//   ticketId: number;
//   rating: number;
// }
//
// export type Task = RepairTask | ExpressTask;

export interface TaskRecord {
  [id: number]: Task;
}

export enum TaskActionTypes {
  ADD = "TASK/ADD",
  ADD_RANGE = "TASK/ADD_RANGE",
}

export interface TaskState {
  readonly allIds: number[];
  readonly byId: TaskRecord;
}
