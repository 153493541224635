import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApplicationState } from "../../../store";
import { Task, Ticket } from "../../../models";
import { useParams } from "react-router";
import { UserInfoState } from "../../../store/userinfo/types";
import { message } from "antd";
import { getTask } from "../../../api/task";
import { findView } from "./view-config";

interface GetTaskResponse {
  ticket: Ticket;
  task: Task;
}

const TaskDetail: FunctionComponent = () => {
  const { taskId } = useParams();
  const { data: userInfo } = useSelector<ApplicationState, UserInfoState>(state => state.userInfo);
  const [ticket, setTicket] = useState<Ticket>();
  const loadTask = async (taskId: number) => {
    try {
      const result: GetTaskResponse = await getTask(taskId);
      const ticket: Ticket = result.ticket;
      ticket.tasks = [result.task];
      setTicket(ticket);
    } catch (e) {
      console.error(e);
      message.error(e.message);
    }
  };
  useEffect(() => {
    if (!ticket) loadTask(Number(taskId)).then(r => r);
  }, [taskId]);
  if (!userInfo || !ticket || !ticket.tasks[0]) {
    return null;
  }
  return findView(userInfo.organization.service, userInfo.level, {
    ticket,
    task: ticket.tasks[0],
    refreshTaskFn: async task => {
      if (task) {
        ticket.tasks = [task];
        return setTicket({ ...ticket });
      }
      await loadTask(Number(taskId));
    },
    refreshTicketFn: async ticket => {
      if (ticket) {
        setTicket(ticket);
        return;
      }
      await loadTask(Number(taskId));
    },
  });
};
export default TaskDetail;
