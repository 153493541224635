import { ActionType } from "typesafe-actions";
import { AuthActionTypes, AuthState } from "./types";
import * as AuthActions from "./actions";
import { LOCATION_CHANGE, RouterAction } from "connected-react-router";

export const initialState: AuthState = {
  loading: false,
  error: undefined,
  data: undefined,
};

const authReducer = (state = initialState, action: ActionType<typeof AuthActions> | RouterAction): AuthState => {
  switch (action.type) {
    case AuthActionTypes.SIGN_IN_REQUEST:
      return { ...state, loading: true, error: undefined };
    case AuthActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload,
      };
    case AuthActionTypes.SIGN_IN_ERROR:
      return { ...state, loading: false, error: action.payload };
    case AuthActionTypes.SIGN_OUT_SUCCESS:
      return { ...state, data: undefined };
    case LOCATION_CHANGE:
      return { ...state, loading: false, error: undefined };
    default:
      return state;
  }
};

export default authReducer;
