import React from "react";
import { NameValuePair } from "../../models";
import { ColumnProps } from "antd/es/table";
import { FetchRecord } from "../../components/list-card";
import { ExpressQueryListViewConfig } from "./ExpressQueryListViewConfig";
import { RepairQueryListViewConfig } from "./RepairQueryListViewConfig";
import { CustomerAdminListViewConfig } from "./CustomerAdminListViewConfig";
import { CustomerStaffListViewConfig } from "./CustomerStaffListViewConfig";
import { RepairStaffListViewConfig } from "./RepairStaffListViewConfig";
import { RepairAdminListViewConfig } from "./RepairAdminListViewConfig";
import { ExpressListViewConfig } from "./ExpressListViewConfig";
import { TicketQueryUnderBrandListViewConfig } from "./TicketQueryUnderBrandListViewConfig";
import { TicketQueryUnderOrgListViewConfig } from "./TicketQueryUnderOrgListViewConfig";
import { RepairAllTicketsQueryViewConfig } from "./RepairAllTicketsQueryViewConfig";
import { CustomerAdminTicketsListViewConfig } from "./CustomerAdminTicketsListViewConfig";

/**
 * ListView的导航条属性定义
 */
export interface ListViewNavBarComponentProps {
  /**
   * 组件内容变更的回调函数
   * ListView组件会实现该函数行为
   * @param params
   */
  filterChange: (params: NameValuePair<any>) => void | Promise<void>;
}

/**
 * ListView配置定义
 */
export interface ListViewConfig<T> {
  /**
   * 组件唯一键
   */
  key: string;
  /**
   * 标题
   */
  title: string;
  /**
   * 导航组件
   * @param props {ListViewNavBarComponentProps}
   */
  navBar?: (props: ListViewNavBarComponentProps) => React.ReactNode;
  /**
   * 视图列定义
   */
  columns: ColumnProps<T>[];
  /**
   * 行关键字段定义
   */
  rowKey: string;
  /**
   * 数据抓取定义
   * @param pageNo {number}
   * @param pageSize {number}
   * @param params {NameValuePair}
   */
  fetch: (pageNo: number, pageSize: number, params?: NameValuePair<any>) => FetchRecord<T> | Promise<FetchRecord<T>>;
  /**
   * 是否立即抓取数据
   * 如果设置为true，那么ListView在mount以后立刻运行fetch函数
   */
  fetchImmediately?: boolean;
}

/**
 * 配置表，用于ListView通过指定mode参数来获取对于配置
 */
const ListViewConfigMap: NameValuePair<ListViewConfig<any>[]> = {
  "express-list": ExpressListViewConfig,
  "org-repair-list": RepairAdminListViewConfig,
  "my-repair-list": RepairStaffListViewConfig,
  "basic-list": CustomerStaffListViewConfig,
  "admin-basic-list": CustomerAdminTicketsListViewConfig,
  "pending-list": CustomerAdminListViewConfig,
  "repair-task-query": RepairQueryListViewConfig,
  "repair-all-tickets-query": RepairAllTicketsQueryViewConfig,
  "express-task-query": ExpressQueryListViewConfig,
  "ticket-query-brand": TicketQueryUnderBrandListViewConfig,
  "ticket-query-org": TicketQueryUnderOrgListViewConfig,
};

export function findListViewConfig(mode: string): ListViewConfig<any>[] {
  return ListViewConfigMap[mode] || [];
}
