import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Divider, Form, message } from "antd";
import { ApplicationState } from "../../../../store";
import { UserInfo } from "../../../../store/userinfo/types";
import { formItemLayout } from "../../../../styles/form";
import { getUsersInOrg } from "../../../../api/user";
import { assignTask } from "../../../../api/task";
import ReadonlyTicket from "../../../../components/readonly-ticket";
import AssignTaskForm, { AssignTaskFormProps } from "./assign-form";
import { User, UserLevel } from "../../../../models";
import { TaskComponentProps } from "../TaskComponentProps";
import TimelineComponent from "../../../../components/timeline";

export interface Operator {
  id: number;
  name: string;
}

const NeedAssign: FunctionComponent<TaskComponentProps> = ({ ticket, task, refreshTaskFn, refreshTicketFn }) => {
  const [operators, setOperators] = useState<Operator[]>([]);
  const userInfo = useSelector<ApplicationState, UserInfo | undefined>(state => state.userInfo?.data);
  let formRef: any;
  const saveFormRef = (ref: any) => {
    formRef = ref;
  };
  const handleSubmit = (e: React.FormEvent) => {
    const { form } = formRef.props;
    form.validateFields(async (err: any, values: AssignTaskFormProps) => {
      e.preventDefault();
      if (err) {
        return;
      }
      if (!values.assignTo) {
        return;
      }
      try {
        const res = await assignTask(task.id, values.assignTo, values.assignDesc, values.serviceTime?.format("YYYY-MM-DD"));
        // const res = await setTaskStatus(task.id, TaskStatus.ToBeAccepted);
        await refreshTaskFn(res);
      } catch (err) {
        message.error(err.message);
      }
    });
  };
  const loadOperators = async (orgId: number) => {
    const res: User[] = await getUsersInOrg(orgId, UserLevel.STAFF);
    const ops: Operator[] = res.map(person => ({
      id: person.id,
      name: `${person.lastName}${person.firstName}`,
    }));
    setOperators(ops);
  };

  useEffect(() => {
    if (userInfo) {
      loadOperators(userInfo.organization.id).then(r => r);
    }
  }, [userInfo]);

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      <Divider />
      <Form.Item label="备注" {...formItemLayout}>
        {task.comment}
      </Form.Item>
      <AssignTaskForm wrappedComponentRef={saveFormRef} people={operators} onSubmit={handleSubmit} />
      <Divider />
      <TimelineComponent ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default NeedAssign;
