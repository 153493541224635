import React, { FunctionComponent } from "react";
import { css } from "@emotion/core";
import { Button, Card, Divider, Form, message } from "antd";
import { formItemLayoutWithOutLabel } from "../../../../styles/form";
import { setTaskStatus } from "../../../../api/task";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import Timeline from "../../../../components/timeline";
import ReadonlyExpressTask from "../../../../components/readonly-express-task";
import ReadonlyRepairTask from "../../../../components/readonly-repair-task";
import { ServiceType, Task, TaskStatus } from "../../../../models";
import { TicketComponentProps } from "../TicketComponentProps";

const SolutionUnderReviewTicketDetail: FunctionComponent<TicketComponentProps> = ({ ticket, refreshTicketFn }) => {
  const tasks = ticket.tasks;
  const approveTask = async (taskId: number) => {
    try {
      await setTaskStatus(taskId, TaskStatus.ToBeRated);
      await refreshTicketFn();
    } catch (err) {
      message.error(err.message);
    }
  };

  const rejectTask = async (taskId: number) => {
    try {
      await setTaskStatus(taskId, TaskStatus.Rejected);
      await refreshTicketFn();
    } catch (err) {
      message.error(err.message);
    }
  };

  const getTaskComponent = (task: Task) => {
    if (task.type === ServiceType.EXPRESS) {
      return <ReadonlyExpressTask key={task.id} task={task} showPrice={true} />;
    } else if (task.type === ServiceType.REPAIR) {
      return <ReadonlyRepairTask key={task.id} task={task} showPrice={true} />;
    } else {
      return null;
    }
  };

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      {tasks.map(task => {
        if (!task) {
          return null;
        }
        return (
          <div key={task.id}>
            <Divider />
            {getTaskComponent(task)}
            {task.status === TaskStatus.SolutionUnderReview ? (
              <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="primary" onClick={async () => approveTask(task.id)} css={ButtonMargin}>
                  同意
                </Button>
                <Button type="danger" onClick={async () => rejectTask(task.id)}>
                  拒绝
                </Button>
              </Form.Item>
            ) : null}
          </div>
        );
      })}
      <Divider />
      <Timeline ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default SolutionUnderReviewTicketDetail;
const ButtonMargin = css`
  margin-right: 14px;
`;
