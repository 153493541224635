import { UserLevel } from "../../models";
import { MenuConfig } from "../MenuConfig";

/**
 * 管理员导航栏
 * @param prefix
 * @constructor
 */
const AdminNavigators = (prefix: string): MenuConfig[] => [
  {
    name: "品牌维护",
    url: `${prefix}/opm/brand/list`,
    icon: "trademark",
    isRouter: true,
    component: () => import("./brand/brand-list-view"),
  },
  {
    name: "机构维护",
    url: `${prefix}/opm/org/list`,
    icon: "apartment",
    isRouter: true,
    component: () => import("./org/org-list-view"),
  },
  {
    name: "供应商关联",
    url: `${prefix}/opm/org/relationship`,
    icon: "apartment",
    isRouter: true,
    component: () => import("./org/vendor-relationship-list-view"),
  },
  {
    name: "用户维护",
    url: `${prefix}/opm/user/list`,
    icon: "team",
    isRouter: true,
    component: () => import("./user/user-list-view"),
  },
  {
    name: "搜索全部保修单",
    url: `${prefix}/list-view/repair-all-tickets-query`,
    icon: "search",
    isRouter: false,
  },
  {
    name: "我的信息",
    url: `${prefix}/my/userinfo/`,
    icon: "user",
    isRouter: true,
    component: () => import("../my/userinfo/UserInfo"),
  },
];

const Navigators = {
  [`${UserLevel.ADMIN}`]: AdminNavigators,
};

export function findNavigator(level: number, prefix: string): MenuConfig[] {
  const nav = Navigators[`${level}`];
  if (!nav) {
    return [];
  }
  return nav(prefix);
}

export function getRouters(prefix: string) {}
