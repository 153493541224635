import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import { History } from "history";
import { connectRouter, RouterState } from "connected-react-router";

import { AuthState } from "./auth/types";
import AuthReducer from "./auth/reducer";
import AuthSaga from "./auth/sagas";

import { UserInfoState } from "./userinfo/types";
import UserInfoReducer from "./userinfo/reducer";
import UserInfoSaga from "./userinfo/sagas";

import { IssueState } from "./issue/types";
import IssueReducer from "./issue/reducer";

import { TicketState } from "./ticket/types";
import TicketReducer from "./ticket/reducer";

import { TaskState } from "./task/types";
import TaskReducer from "./task/reducer";

export interface ApplicationState {
  router: RouterState;
  auth: AuthState;
  userInfo: UserInfoState;
  issue: IssueState;
  ticket: TicketState;
  task: TaskState;
}

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    userInfo: UserInfoReducer,
    issue: IssueReducer,
    ticket: TicketReducer,
    task: TaskReducer,
  });

export function* rootSaga() {
  yield all([fork(AuthSaga), fork(UserInfoSaga)]);
}
