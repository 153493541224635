import { action } from "typesafe-actions";
import { TicketActionTypes } from "./types";
import { Ticket, Timeline } from "../../models";

export const add = (ticket: Ticket) => action(TicketActionTypes.ADD, ticket);

export const addRange = (tickets: Ticket[]) => action(TicketActionTypes.ADD_RANGE, tickets);

export const setTimeline = (ticketId: number, timeline: Timeline[]) =>
  action(TicketActionTypes.SET_TIMELINE, { ticketId, timeline });
