import React, { FunctionComponent } from "react";
import { Card, Divider, Form } from "antd";

import { ticketStatusToText } from "../../../utils/ticket";
import { formItemLayout } from "../../../styles/form";

import ReadonlyTicket from "../../../components/readonly-ticket";
import Timeline from "../../../components/timeline";
import { TicketComponentProps } from "./TicketComponentProps";
import { ServiceType, Task } from "../../../models";
import ReadonlyRepairTask from "../../../components/readonly-repair-task";

const DefaultTicketDetail: FunctionComponent<TicketComponentProps> = ({ ticket, refreshTicketFn }) => {
  const tasks = ticket.tasks;
  const getTaskComponent = (task: Task) => {
    if (task.type === ServiceType.REPAIR) {
      return <ReadonlyRepairTask key={task.id} task={task} showDetail={false} />;
    } else {
      return null;
    }
  };
  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />
      {tasks.map(task => {
        return (
          task && (
            <div key={task.id}>
              <Divider />
              {getTaskComponent(task)}
            </div>
          )
        );
      })}
      <Divider />
      <Form.Item label="工单状态" {...formItemLayout}>
        {ticketStatusToText(ticket.status)}
      </Form.Item>
      <Divider />
      <Timeline ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};

export default DefaultTicketDetail;
