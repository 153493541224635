import React, { FunctionComponent, useCallback } from "react";
import styled from "@emotion/styled";
import { Dropdown, Icon, Layout, Menu, Typography } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { UserInfoState } from "../../store/userinfo/types";
import { signOutRequest } from "../../store/auth/actions";

const { Text } = Typography;
export const Header: FunctionComponent = () => {
  const { data: userInfo } = useSelector<ApplicationState, UserInfoState>(state => state.userInfo);
  const dispatch = useDispatch();
  const signOut: any = useCallback(() => dispatch(signOutRequest()), [dispatch]);
  return (
    <DashboardHeader>
      <HeaderActionBar>
        <HeaderActionDropdown
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Link to={`/dashboard/my/userinfo`}>我的信息</Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="1" onClick={() => signOut()}>
                登出
              </Menu.Item>
            </Menu>
          }>
          <Text className="ant-dropdown-link">
            欢迎，{`${userInfo?.userName}`} <Icon type="more" />
          </Text>
        </HeaderActionDropdown>
      </HeaderActionBar>
    </DashboardHeader>
  );
};
const DashboardHeader = styled(Layout.Header)`
  background: #fff;
  padding: 0;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
`;

const HeaderActionBar = styled.div`
  float: right;
`;

const HeaderAction = styled.div`
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.025);
  }
`;
const HeaderActionDropdown = HeaderAction.withComponent(Dropdown);
