import { ActionType } from "typesafe-actions";
import { UserInfoActionTypes, UserInfoState } from "./types";
import * as UserInfoActions from "./actions";

export const initialState: UserInfoState = {
  loading: false,
  error: undefined,
  data: undefined,
};

const userInfoReducer = (state = initialState, action: ActionType<typeof UserInfoActions>): UserInfoState => {
  switch (action.type) {
    case UserInfoActionTypes.FETCH_REQUEST:
      return { ...state, loading: true, error: undefined };
    case UserInfoActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload,
      };
    case UserInfoActionTypes.FETCH_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default userInfoReducer;
