import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApplicationState } from "../../../store";
import { UserInfoState } from "../../../store/userinfo/types";
import { Ticket } from "../../../models";
import { getTicket } from "../../../api/ticket";
import { message } from "antd";
import { useParams } from "react-router";
import { findView } from "./view-config";

const TicketDetail: FunctionComponent = React.memo(() => {
  const { ticketId } = useParams();
  const { data: userInfo } = useSelector<ApplicationState, UserInfoState>(state => state.userInfo);
  const [ticket, setTicket] = useState<Ticket>();
  const loadTicket = async (ticketId: number) => {
    try {
      const result: Ticket = await getTicket(ticketId);
      setTicket(result);
    } catch (e) {
      console.error(e);
      message.error(e.message);
    }
  };
  useEffect(() => {
    if (!ticket) loadTicket(Number(ticketId)).then(r => r);
  }, [ticketId]);

  if (!userInfo || !ticket) {
    return null;
  }
  return findView(userInfo, {
    ticket,
    refreshTicketFn: async ticket => {
      if (ticket) {
        setTicket(ticket);
        return;
      }
      await loadTicket(Number(ticketId));
    },
  });
});
export default TicketDetail;
