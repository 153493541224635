import React from "react";
import {OrgType, TicketStatus, UserLevel} from "../../../models";
import Rejected from "./operator/rejected";
import DefaultWithTasks from "./default-with-tasks";
import ToBeRated from "./operator/to-be-rated";
import UnderReview from "./admin/under-review";
import SolutionUnderReview from "./admin/solution-under-review";
import Default from "./default";
import {TicketComponentProps} from "./TicketComponentProps";
import {UserInfo} from "../../../store/userinfo/types";

interface ViewConfig {
  [status: string]: (componentProps: TicketComponentProps) => JSX.Element;
}

/**
 * 操作员视图配置
 */
const OperatorViewConfig: ViewConfig = {
  [TicketStatus[TicketStatus.Rejected]]: (componentProps: TicketComponentProps) =>
    <Rejected {...componentProps} />,
  [TicketStatus[TicketStatus.Processing]]: (componentProps: TicketComponentProps) => (
    <DefaultWithTasks {...componentProps} />
  ),
  [TicketStatus[TicketStatus.Finished]]: (componentProps: TicketComponentProps) => (
    <DefaultWithTasks {...componentProps} />
  ),
  [TicketStatus[TicketStatus.ToBeRated]]: (componentProps: TicketComponentProps) =>
    <ToBeRated {...componentProps} />,
};
/**
 * 管理员视图配置
 */
const AdminViewConfig: ViewConfig = {
  [TicketStatus[TicketStatus.UnderReview]]: (componentProps: TicketComponentProps) => (
    <UnderReview {...componentProps} />
  ),
  [TicketStatus[TicketStatus.SolutionUnderReview]]: (componentProps: TicketComponentProps) => (
    <SolutionUnderReview {...componentProps} />
  ),
  [TicketStatus[TicketStatus.Processing]]: (componentProps: TicketComponentProps) => (
    <DefaultWithTasks {...componentProps} />
  ),
  [TicketStatus[TicketStatus.ToBeRated]]: (componentProps: TicketComponentProps) => (
    <DefaultWithTasks {...componentProps} />
  ),
  [TicketStatus[TicketStatus.Finished]]: (componentProps: TicketComponentProps) => (
    <DefaultWithTasks {...componentProps} />
  ),
};
const DefaultViewConfig = (componentProps: TicketComponentProps) =>
  <Default {...componentProps} />;
const DefaultWithTasksViewConfig = (componentProps: TicketComponentProps) =>
  <DefaultWithTasks {...componentProps} />;

export function findView(userInfo: UserInfo, componentProps: TicketComponentProps) {
  if (!componentProps.ticket) {
    return null;
  }
  let viewConfig = null;
  let fn;
  if (userInfo.orgType === OrgType.CUSTOMER) {
    if (userInfo.level === UserLevel.STAFF) {
      viewConfig = OperatorViewConfig;
    } else if (userInfo.level === UserLevel.ADMIN) {
      viewConfig = AdminViewConfig;
    } else {
      return null;
    }
    fn = viewConfig[TicketStatus[componentProps.ticket?.status]] || DefaultViewConfig;
  } else {
    fn = DefaultWithTasksViewConfig
  }
  return fn(componentProps);
}
