import { ActionType } from "typesafe-actions";
import { TicketActionTypes, TicketRecord, TicketState } from "./types";
import * as TicketActions from "./actions";
import union from "lodash/union";

export const initialState: TicketState = {
  allIds: [],
  byId: {},
};

const ticketReducer = (state = initialState, action: ActionType<typeof TicketActions>): TicketState => {
  switch (action.type) {
    case TicketActionTypes.ADD: {
      return {
        ...state,
        allIds: union(state.allIds, [action.payload.id]),
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    }
    case TicketActionTypes.ADD_RANGE: {
      const allIds: number[] = action.payload.map(ticket => ticket.id);
      const byId: TicketRecord = action.payload.reduce((obj, ticket) => {
        obj[ticket.id] = ticket;
        return obj;
      }, {} as TicketRecord);
      return {
        ...state,
        allIds: union(state.allIds, allIds),
        byId: { ...state.byId, ...byId },
      };
    }
    case TicketActionTypes.SET_TIMELINE: {
      const ticket = state.byId[action.payload.ticketId];
      if (ticket === undefined) {
        return state;
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.ticketId]: {
            ...ticket,
            timeline: action.payload.timeline,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default ticketReducer;
