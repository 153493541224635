import { DELETE, GET, POST, PUT } from "../utils/api";
import { Organization, Status } from "../models";
import { OrgRange } from "../models/OrgRange";
import { OrganizationRelationship } from "../models/OrganizationRelationship";

export async function getOrgsByType(type?: number, status?: Status, isAll?: OrgRange) {
  // type=0就是不限制类型
  return GET({ path: `/org`, params: { type, status, isAll } });
}

export async function getOrgsByRelationship(brandId: number): Promise<OrganizationRelationship[]> {
  return GET({ path: `/orgRelations/${brandId}` });
}

export async function addOrgRelationship(brandId: number, relationOrgId: number) {
  return POST({ path: `/myOrg`, body: { brandId, relationOrgId } });
}

export async function deleteOrgRelationship(id: number) {
  return DELETE({ path: `/myOrg/${id}` });
}

type ChangeOrgStatusPayload = { orgStatus: number };

export async function changeOrgStatus(orgId: number, payload: ChangeOrgStatusPayload) {
  return PUT({ path: `/org/${orgId}/status`, body: payload });
}

export async function addOrg(organization: Organization) {
  return POST({ path: `/org`, body: organization });
}

export async function updateOrg(organization: Organization) {
  return PUT({ path: `/org/${organization.id}`, body: organization });
}
