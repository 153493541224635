import React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { persistStore } from "redux-persist";

import Main from "./main";
import configureStore from "./configureStore";
import * as serviceWorker from "./serviceWorker";

export const history = createBrowserHistory();

const initialState: any = {};
const store = configureStore(history, initialState);
const persistor = persistStore(store);

ReactDOM.render(<Main store={store} persistor={persistor} history={history} />, document.getElementById("root"));
serviceWorker.register({
  onSuccess(registration: ServiceWorkerRegistration) {
    console.log("onSuccess", registration);
  },
  onUpdate(registration: ServiceWorkerRegistration) {
    console.log("onUpdate", registration);
  },
});
console.log(`System Version:${process.env.SYSTEM_VERSION}`);
