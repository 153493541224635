import React, { FunctionComponent, useState } from "react";
import { css } from "@emotion/core";
import { Button, Card, Divider, Form, message, Rate } from "antd";
import { setTimeline } from "../../../../store/ticket/actions";

import { ticketStatusToText } from "../../../../utils/ticket";
import { formItemLayout, formItemLayoutWithOutLabel } from "../../../../styles/form";

import { addComment, getTicket, setTicketStatus } from "../../../../api/ticket";
import { rateTask } from "../../../../api/task";

import ReadonlyTicket from "../../../../components/readonly-ticket";
import ReadonlyExpressTask from "../../../../components/readonly-express-task";
import ReadonlyRepairTask from "../../../../components/readonly-repair-task";
import TimelineComponent from "../../../../components/timeline";
import RejectFinishForm, { RejectFinishFormProps } from "./reject-finish-form";
import { ServiceType, Task, TaskStatus, TicketStatus, Timeline } from "../../../../models";
import { TicketComponentProps } from "../TicketComponentProps";

const ToBeRatedTicketDetail: FunctionComponent<TicketComponentProps> = ({ ticket, refreshTicketFn }) => {
  const [showRejectForm, setShowRejectForm] = useState<boolean>(false);
  const tasks = ticket.tasks;
  let rejectFormRef: any;

  const saveRejectFormRef = (formRef: any) => {
    rejectFormRef = formRef;
  };

  const handleReject = async () => {
    const { form } = rejectFormRef.props;
    form.validateFields(async (err: any, values: RejectFinishFormProps) => {
      if (err) {
        return;
      }

      try {
        // 疑问点：未完成目前的逻辑好像只是加了备注信息，没有任何状态变迁
        const timeline: Timeline[] = await addComment(ticket.id, values.comment);
        setTimeline(ticket.id, timeline);

        rejectFormRef.reset();
        setShowRejectForm(false);

        const res = await getTicket(ticket.id);
        await refreshTicketFn(res);
      } catch (err) {
        message.error(err.message);
      }
    });
  };

  const handleCancelReject = () => {
    rejectFormRef.reset();
    setShowRejectForm(false);
  };

  const getTaskComponent = (task: Task) => {
    if (task.type === ServiceType.EXPRESS) {
      return <ReadonlyExpressTask key={task.id} task={task} />;
    } else if (task.type === ServiceType.REPAIR) {
      return <ReadonlyRepairTask key={task.id} task={task} />;
    } else {
      return null;
    }
  };

  const getTaskRate = (task: Task) => {
    if (task.status === TaskStatus.ToBeRated) {
      return (
        <Form.Item label="维修评价" {...formItemLayout}>
          <Rate onChange={score => doRate(task.id, score)} />
        </Form.Item>
      );
    } else if (task.status === TaskStatus.Finished) {
      return (
        <Form.Item label="维修评价" {...formItemLayout}>
          <Rate disabled defaultValue={task.rating} />
        </Form.Item>
      );
    } else {
      return null;
    }
  };

  const doRate = async (taskId: number, score: number) => {
    try {
      const res = await rateTask(taskId, score);
      // await setTaskStatus(taskId, TaskStatus.Finished);
      await refreshTicketFn(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  const finishTicket = async () => {
    try {
      await setTicketStatus(ticket.id, TicketStatus.Finished);
      await refreshTicketFn();
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Card>
      <ReadonlyTicket ticket={ticket} />

      {tasks.map(task => {
        if (!task) {
          return null;
        }

        return (
          <div key={task.id}>
            <Divider />
            {getTaskComponent(task)}
          </div>
        );
      })}

      <Divider />
      <div css={NoMargin}>
        <Form.Item label="工单状态" {...formItemLayout}>
          {ticketStatusToText(ticket.status)}
        </Form.Item>
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="primary" onClick={finishTicket} css={ButtonMargin}>
            确认完成
          </Button>
          <Button
            type="danger"
            onClick={() => {
              setShowRejectForm(true);
            }}>
            未完成
          </Button>
        </Form.Item>
      </div>

      <RejectFinishForm
        wrappedComponentRef={saveRejectFormRef}
        visible={showRejectForm}
        onCancel={handleCancelReject}
        onSubmit={handleReject}
      />

      <Divider />
      <TimelineComponent ticket={ticket} refreshTicketFn={refreshTicketFn} />
    </Card>
  );
};
export default ToBeRatedTicketDetail;

const NoMargin = css`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const ButtonMargin = css`
  margin-right: 14px;
`;
