import { getAttachmentUrl } from "./attachment";
import { Attachment } from "../models";

export const normalizeFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const filesToAttachments = (files: any[]): Attachment[] => {
  if (!files) {
    return [];
  }

  const response = files
    .filter(file => file.status === "done")
    .map(
      (file): Attachment => ({
        attachmentId: file.response.data.id,
        attachmentName: file.response.data.fileName || file.response.data.file_name,
      }),
    );
  return response;
};

export const attachmentsToFiles = (attachments: Attachment[]): any[] => {
  return attachments.map(attachment => {
    const id = attachment.attachmentId;
    const name = attachment.attachmentName;

    return {
      uid: id,
      name: name,
      status: "done",
      url: getAttachmentUrl(id),
      response: {
        data: {
          id: id,
          file_name: name,
        },
      },
    };
  });
};
