import {UserLevel} from "../../models";
import {MenuConfig} from "../MenuConfig";

/**
 * 管理员导航栏
 * @param prefix
 * @constructor
 */
const AdminNavigators = (prefix: string): MenuConfig[] => [
  {
    name: "首页",
    url: `${prefix}/list-view/pending-list`,
    icon: "home",
    isRouter: false,
  },
  {
    name: "保修单视图",
    url: `${prefix}/list-view/admin-basic-list`,
    icon: "home",
    isRouter: false,
  },
  {
    name: "录入保修单",
    url: `${prefix}/customer/ticket/new`,
    icon: "plus-circle",
    isRouter: true,
    component: () => import("./create-ticket"),
  },
  {
    name: "搜索",
    url: `${prefix}/list-view/ticket-query-brand`,
    icon: "search",
    isRouter: false,
  },
  {
    name: "报表分析",
    url: `${prefix}/customer/report`,
    icon: "bar-chart",
    isRouter: true,
    component: () => import("./report"),
  },
  {
    name: "管理常见问题",
    url: `${prefix}/customer/issue_settings`,
    icon: "setting",
    isRouter: true,
    component: () => import("./issue-setting"),
  },
  {
    name: "我的信息",
    url: `${prefix}/my/userinfo/`,
    icon: "user",
    isRouter: true,
    component: () => import("../my/userinfo/UserInfo"),
  },
];

/**
 * 操作员导航栏
 * @param prefix
 * @constructor
 */
const StaffNavigators = (prefix: string): MenuConfig[] => [
  {
    name: "首页",
    url: `${prefix}/list-view/basic-list`,
    icon: "home",
    isRouter: false,
  },
  {
    name: "录入保修单",
    url: `${prefix}/customer/ticket/new`,
    icon: "plus-circle",
    isRouter: true,
    component: () => import("./create-ticket"),
  },
  {
    name: "详细查询",
    url: `${prefix}/list-view/ticket-query-org`,
    icon: "table",
    isRouter: false,
  },
  {
    name: "报表分析",
    url: `${prefix}/customer/report`,
    icon: "bar-chart",
    isRouter: true,
    component: () => import("./report"),
  },
  {
    name: "我的信息",
    url: `${prefix}/my/userinfo/`,
    icon: "user",
    isRouter: true,
    component: () => import("../my/userinfo/UserInfo"),
  },
];
const Navigators = {
  [`${UserLevel.ADMIN}`]: AdminNavigators,
  [`${UserLevel.STAFF}`]: StaffNavigators,
};

export function findNavigator(level: number, prefix: string): MenuConfig[] {
  const nav = Navigators[`${level}`];
  if (!nav) {
    return [];
  }
  return nav(prefix);
}
